import React from 'react';

export const HomeIcon = ({ style }) => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M22.732 18.2016V14.5613H15.2654C14.2344 14.5613 13.3987 13.7464 13.3987 12.7411C13.3987 11.7359 14.2344 10.921 15.2654 10.921H22.732V7.28064L28.332 12.7411L22.732 18.2016ZM19.932 16.3814C20.4475 16.3814 20.8654 16.7889 20.8654 17.2915V22.6366C20.8654 23.203 20.3945 23.6621 19.8137 23.6621H11.532V26.1775C11.532 27.5306 10.0717 28.4106 8.83057 27.8055L0.332031 23.6621V0H19.532C20.2684 0 20.8654 0.582081 20.8654 1.30011V8.19072C20.8654 8.69335 20.4475 9.1008 19.932 9.1008C19.4166 9.1008 18.9987 8.69335 18.9987 8.19072V1.82016H4.06536L10.5002 4.9574C11.1326 5.26572 11.532 5.89598 11.532 6.5854V21.8419H18.9987V17.2915C18.9987 16.7889 19.4166 16.3814 19.932 16.3814Z"
        fill="#3D3729"
      />
    </svg>
  );
};
