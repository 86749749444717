import React, { Fragment } from 'react';
import LevelParametersAbstract, { MODEL } from '../LevelParametersAbstract';
import { CONTROL_COMMANDS } from '../../ControlCommands/CommandAbstract';
import { makeObservable, observable } from 'mobx';
import TheCommandLeftward from '../../ControlCommands/commands/TheCommandLeftward';
import TheCommandRightward from '../../ControlCommands/commands/TheCommandRightward';
import TheCommandRise from '../../ControlCommands/commands/TheCommandRise';
import TheCommandDescend from '../../ControlCommands/commands/TheCommandDescend';
import TheCommandHook from '../../ControlCommands/commands/TheCommandHook';
import Notifications, { BUTTON_EVENT, NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import { BUTTON_MODIFICATIONS, GAME_BUTTON_MODIFICATIONS } from '../../../common/constants';
import RootStore from '../../index';
import Tutorial from '../../Tutorial/Tutorial';
import {
  endGame,
  fail_6,
  fail_7,
  level3_button_step4,
  level3_button_step5,
  level3_button_step6,
  level3_button_step7,
  level3_button_step8,
  level3_step1,
  level3_step2,
  level3_step3,
  level3_step4,
  level3_step5,
  level3_step6,
  level3_step7,
  level3_step8,
  level3_step9,
} from '../../../ui';
import _ from 'lodash';

const tutorialPreset = {
  1: {
    title: 'Добро пожаловать на 3 уровень',
    media: { type: 'img', path: level3_step1 },
    content: (
      <Fragment>
        <span>
          Теперь кошечка находится на строящемся объекте. Также здесь появилась высота игровых объектов, которая
          достигает 3 этажей
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  2: {
    title: 'Алгоритм',
    media: { type: 'img', path: level3_step2 },
    content: (
      <Fragment>
        <span>
          Вам нужно составить одну длинную последовательность действий, чтобы пройти уровень. После того, как вы
          вставили в последовательность действий все необходимые команды, запустите его
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  3: {
    title: 'Новые команды',
    media: { type: 'img', path: level3_step3 },
    content: (
      <Fragment>
        <span>В отличие от предыдущих уровней произойдут изменения в блоке команд</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  4: {
    title: 'Команда “Влево”',
    media: { type: 'video', path: level3_step4 },
    content: (
      <Fragment>
        <span>Обратите внимание, при нажатии</span>
        <img src={level3_button_step4} />
        <span>
          кошечка не просто поворачивается на месте в нужную сторону, а сразу идет в левую сторону на 1 клетку
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  5: {
    title: 'Команда “Вправо”',
    media: { type: 'video', path: level3_step5 },
    content: (
      <Fragment>
        <span>При нажатии</span>
        <img src={level3_button_step5} />
        <span>кошечка сразу передвигается в правую сторону на 1 блок</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  6: {
    title: 'Команда “Подняться”',
    media: { type: 'video', path: level3_step6 },
    content: (
      <Fragment>
        <img src={level3_button_step6} />
        <span>данная команда нужна для того, чтобы подняться по лестнице на один этаж вверх</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  7: {
    title: 'Команда “Спуститься”',
    media: { type: 'video', path: level3_step7 },
    content: (
      <Fragment>
        <img src={level3_button_step7} />
        <span>при помощи данной команды кошечка может спуститься с лестницы на один этаж вниз</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  8: {
    title: 'Команда “Зацепить”',
    media: { type: 'video', path: level3_step8 },
    content: (
      <Fragment>
        <img src={level3_button_step8} />
        <span>кошечка при помощи данной команды прицепляет к строительному крану блок-комнату</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  9: {
    title: 'Проигрыш',
    media: { type: 'video', path: level3_step9 },
    content: (
      <Fragment>
        <span>
          Проиграть на данном уровне возможно, если кошечка падает со здания, либо же в том случае, если
          последовательность действий не может быть исполнена и если в конце последовательности действий кошечка не
          выполнила цель
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  10: {
    title: 'Задание',
    content: (
      <Fragment>
        <span>Необходимо дойти кошечкой до строительного крюка и зацепить его к зданию</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.START,
  },
};
const modelSettings = {
  [MODEL.ENVIRONMENT]: {
    scale: 0.004,
    path: '/models/common/environment/BackGround.fbx',
  },
  [MODEL.STAGE_ENVIRONMENT]: {
    scale: 0.0033,
    path: '/models/common/sceneEnvironment/Level_03.fbx',
  },
  [MODEL.BASIC]: {
    scale: 0.005,
    path: '/models/level_1/cat/CatMesh_Pose.fbx',
  },
  [MODEL.OBSTACLE]: {
    scale: 0.003,
    path: '/models/level_2/obstacle/RoadSigns(Small)_Pit.fbx',
  },
  [MODEL.CHECKPOINT]: {
    scale: 0.005,
    path: '/models/common/checkpoint/SignExit.fbx',
  },
  [MODEL.SCENE_EDGE]: {
    scale: 1,
    path: '',
  },
  [MODEL.BUILDING]: {
    scale: 0.00275,
    path: '/models/common/GreenHouse/HouseBlock_V1_Door.fbx',
  },
  [MODEL.BUILDING_WITH_HOOK]: {
    scale: 0.00275,
    path: '/models/common/GrayHouse/HouseGrayBlock_V3_Hook.fbx',
  },
  [MODEL.HOOK]: {
    scale: 0.2,
    path: '/models/common/crane/Hook11.fbx',
  },
};
const commandsPreset = {
  [CONTROL_COMMANDS.LEFTWARD]: new TheCommandLeftward(CONTROL_COMMANDS.LEFTWARD),
  [CONTROL_COMMANDS.RIGHTWARD]: new TheCommandRightward(CONTROL_COMMANDS.RIGHTWARD),
  [CONTROL_COMMANDS.HIGHER]: new TheCommandRise(CONTROL_COMMANDS.HIGHER),
  [CONTROL_COMMANDS.BELOW]: new TheCommandDescend(CONTROL_COMMANDS.BELOW),
  [CONTROL_COMMANDS.HOOK]: new TheCommandHook(CONTROL_COMMANDS.HOOK),
};
const notificationsPreset = {
  [NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE]: {
    media: { type: 'video', path: fail_6 },
    content: (
      <span>Кошечка не может пройти через забор, попробуйте составить алгоритм снова, у Вас точно получится!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.FELL_OFF_BUILDING]: {
    media: { type: 'video', path: fail_7 },
    content: (
      <span>
        Нужно соблюдать технику безопасности во время работы на высоте, попробуйте составить алгоритм снова, у Вас точно
        получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.NO_BUILDING]: {
    content: (
      <span>
        Здесь нет здания, на которое кошечка может подняться, попробуйте составить алгоритм снова, у Вас точно
        получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.YOU_CANT_GO_HIGHER]: {
    content: (
      <span>
        Кошечка уже на крыше и не сможет залезть выше, попробуйте составить алгоритм снова, у Вас точно получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.CANT_GO_LOWER]: {
    content: (
      <span>
        Лестницы вниз здесь нет, и кошечка больше не может спускаться, попробуйте составить алгоритм снова, у Вас точно
        получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.BUILDING_HAS_NO_HOOK]: {
    content: <span>Вы ещё не добрались до цели, попробуйте составить алгоритм снова, у Вас точно получится!</span>,
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.NOT_COMPLETED]: {
    content: (
      <span>
        Кошечка выполнила все заданные действия, но зацепить крюк не вышло, попробуйте составить алгоритм снова, у Вас
        точно получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.CHECKPOINT]: {
    content: <span>Вы отлично справились со своей работой! Можем переходить к следующему уровню!</span>,
    buttons: [{ event: BUTTON_EVENT.FURTHER, modifier: BUTTON_MODIFICATIONS.POSITIVE }],
  },
  [NOTIFICATION_TYPES.INVITATION]: {
    media: { type: 'video', path: endGame },
    content: (
      <span>
        Вы неплохо себя показали, попробуйте ещё раз, чтобы добиться лучшего результата! Будем ждать вас в Алабуге!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.EXIT, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
    ],
  },
};

export default class ThirdLevel extends LevelParametersAbstract {
  #models;
  constructor(models) {
    super({ modelSettings, commandsPreset, tutorialPreset, notificationsPreset });
    this.#models = models;
    this.isBuildingHooked = false;
    this.tutorial = new Tutorial(tutorialPreset);
    this.canvasPreset = {
      camera: { position: [0, 8, 25], fov: 50, aspect: 1, near: 1, zoom: { min: 0.9, average: 1.2, max: 1.3 } },
      sceneObjectsPosition: { x: -6.5, y: 0, z: 10 },
    };
    makeObservable(this, {
      notifications: observable,
    });
  }

  init() {
    super.init();
    this.tutorial.open();
  }

  setModels() {
    // Поворот хука в сторону крана
    const hook = _.find(this.#models, { block_type: MODEL.HOOK });
    hook.direction = 45;
    // Добавление окружения сцены
    this.#models.push(
      { block_type: 10, x: 6, y: 0, z: 1, direction: null, exit_side: null },
      { block_type: 11, x: 6.5, y: 0, z: 0, direction: null, exit_side: null },
    );

    super.setModels(this.#models);
  }

  get getModelLadder() {
    const buildings = [];
    const floor = {};
    super.getModels
      .filter((model) => model.block_type === 5 || model.block_type === 6)
      .forEach((el) => {
        buildings.push({ x: el.position.x, y: el.position.y, z: el.position.z });
      });
    const sortedBuildings = _.sortBy(buildings, 'x', 'default');
    sortedBuildings.forEach((el) => {
      floor[el.x] = { x: el.x, z: el.z, floor: el.y + 1 };
    });

    return floor;
  }

  executeCommandStack() {
    const command = super.executeCommandStack();
    if (!command || !super.getCommandExecutionPermission) {
      return;
    }

    command.modifier = GAME_BUTTON_MODIFICATIONS.PERFORMED;
    command.run();

    if (
      this.stack.length - 1 === this.currentCommandIndex &&
      !this.isBuildingHooked &&
      this.getCommandExecutionPermission
    ) {
      command.modifier = GAME_BUTTON_MODIFICATIONS.FAIL;
      this.notifications.open({ type: NOTIFICATION_TYPES.NOT_COMPLETED, delay: 500 });
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    }

    this.endpointCheck(command);
    super.reloadCommand({ delay: 600, command });
  }

  endpointCheck(command) {
    if (this.isBuildingHooked) {
      const position =
        _.find(RootStore.appStore._levelParameters.models, { block_type: MODEL.CHECKPOINT })?.position || null;
      command.modifier = GAME_BUTTON_MODIFICATIONS.DONE;
      super.successfulExecution({ position: position });
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    }

    if (!super.getCommandExecutionPermission) {
      command.modifier = GAME_BUTTON_MODIFICATIONS.FAIL;
    }
  }

  get isDisabledBlock() {
    return super.isCommandStarted;
  }
}
