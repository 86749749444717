import React, { Fragment } from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import Model from '../../../ui/components/Model/Model';
import { Ladder } from '../../../ui/models/Ladder/Ladder';
import { RoadBlock } from '../../../ui/models/RoadBlock/RoadBlock';

// ToDo ПЕРЕДЕЛАТЬ!!!
function LadderModel() {
  const { appStore } = RootStore;
  const ladders = appStore._levelParameters?.getModelLadder || null;
  return (
    <>
      {Object.values(ladders).map((ladder, index) => (
        <Fragment key={index}>
          <Ladder object={ladder} />
        </Fragment>
      ))}
    </>
  );
}

function SceneObjects() {
  const { appStore } = RootStore;
  if (!appStore._levelParameters) {
    return;
  }
  const models = appStore._levelParameters.getModels;
  const position = appStore._levelParameters.canvasPreset.sceneObjectsPosition;
  const isVisibleLadder = appStore.currentLevel === 3;

  return (
    <group position={[position.x, position.y, position.z]}>
      {models.map((object) => (
        <Model key={object.key} object={object} level={appStore.currentLevel} />
      ))}
      {isVisibleLadder && <LadderModel />}
    </group>
  );
}

export default observer(SceneObjects);
