import React from 'react';

export const RiseArrowIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.73618 1.5884L8.55756 1.66231L1.66232 8.55756C1.62702 8.5929 1.60299 8.6379 1.59325 8.68688C1.5835 8.7359 1.58851 8.78672 1.60763 8.8329C1.62676 8.87908 1.65915 8.91855 1.7007 8.94632C1.74221 8.97406 1.791 8.98889 1.84093 8.98893H5.59531C6.03393 8.98893 6.38951 9.34451 6.38951 9.78313V12.1298H11.0828V9.78313C11.0828 9.34451 11.4384 8.98893 11.877 8.98893H15.6313C15.6812 8.98889 15.7301 8.97406 15.7717 8.94632C15.8132 8.91855 15.8456 8.87908 15.8647 8.8329C15.8838 8.78672 15.8889 8.7359 15.8791 8.68688C15.8694 8.6379 15.8453 8.5929 15.81 8.55756L8.91479 1.66231L8.73618 1.5884Z"
        fill="#3D3729"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73618 0C8.24797 0 7.77975 0.193902 7.43448 0.539065L0.539065 7.43448C0.281654 7.69197 0.106365 8.02 0.0353565 8.37709C-0.0356525 8.73419 0.000808697 9.10433 0.140128 9.4407C0.279448 9.77708 0.515371 10.0646 0.818077 10.2669C1.12078 10.4692 1.47684 10.5773 1.84093 10.5773H4.80111V12.924C4.80111 13.3626 5.15669 13.7182 5.59531 13.7182H11.877C12.3157 13.7182 12.6712 13.3626 12.6712 12.924V10.5773H15.6316C15.9957 10.5773 16.3516 10.4692 16.6543 10.2669C16.957 10.0646 17.1929 9.77708 17.3322 9.4407C17.4715 9.10432 17.508 8.73419 17.437 8.37709C17.366 8.01999 17.1906 7.69188 16.9332 7.4344L10.0379 0.539065C9.6926 0.193902 9.22438 0 8.73618 0ZM8.55756 1.66231C8.60495 1.61498 8.66919 1.5884 8.73618 1.5884C8.80316 1.5884 8.8674 1.61498 8.91479 1.66231L15.81 8.55756C15.8453 8.5929 15.8694 8.6379 15.8791 8.68688C15.8889 8.7359 15.8838 8.78672 15.8647 8.8329C15.8456 8.87908 15.8132 8.91855 15.7717 8.94632C15.7301 8.97406 15.6812 8.98889 15.6313 8.98893H11.877C11.4384 8.98893 11.0828 9.34451 11.0828 9.78313V12.1298H6.38951V9.78313C6.38951 9.34451 6.03393 8.98893 5.59531 8.98893H1.84093C1.791 8.98889 1.74221 8.97406 1.7007 8.94632C1.65915 8.91855 1.62676 8.87908 1.60763 8.8329C1.58851 8.78672 1.5835 8.7359 1.59325 8.68688C1.60299 8.6379 1.62702 8.5929 1.66232 8.55756L8.55756 1.66231Z"
        fill="#3D3729"
      />
      <path
        d="M5.5953 15.2707C5.15668 15.2707 4.80111 15.6263 4.80111 16.0649C4.80111 16.5036 5.15668 16.8591 5.5953 16.8591H11.877C12.3157 16.8591 12.6712 16.5036 12.6712 16.0649C12.6712 15.6263 12.3157 15.2707 11.877 15.2707H5.5953Z"
        fill="#3D3729"
      />
      <path
        d="M5.5953 18.4116C5.15668 18.4116 4.80111 18.7672 4.80111 19.2058C4.80111 19.6444 5.15668 20 5.5953 20H11.877C12.3157 20 12.6712 19.6444 12.6712 19.2058C12.6712 18.7672 12.3157 18.4116 11.877 18.4116H5.5953Z"
        fill="#3D3729"
      />
    </svg>
  );
};
