import React from 'react';
import './style.scss';
import { IconButton, SettingIcon } from '../../index';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';

function Header() {
  const { appStore } = RootStore;
  return (
    <div className={classNames({ header_container: true, 'header_container--access': !appStore.settingModalFlag })}>
      <IconButton onClick={() => appStore.openSettingModal()}>
        <SettingIcon />
      </IconButton>
    </div>
  );
}

export default observer(Header);
