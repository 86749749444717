const LOCAL_STORAGE_KEYS = {
  LEVEL: 'LEVEL',
  MAP_UID: 'MAP_UID',
  USER_TOKEN: 'USER_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  NEED_KEYCLOAK: 'NEED_KEYCLOAK',
  TUTORIAL_LAUNCHED: 'TUTORIAL_LAUNCHED',
};

const NotFreezeLocalStorage = {
  remove(key) {
    localStorage.removeItem(key);
  },
};
Object.keys(LOCAL_STORAGE_KEYS).map((key) => {
  Object.defineProperty(NotFreezeLocalStorage, key, {
    get() {
      return localStorage.getItem(LOCAL_STORAGE_KEYS[key]);
    },
    set(value) {
      localStorage.setItem(LOCAL_STORAGE_KEYS[key], value);
    },
    configurable: true,
    enumerable: true,
  });
  return true;
});

const LocalStorage = Object.freeze(NotFreezeLocalStorage);

export { LocalStorage, LOCAL_STORAGE_KEYS };
