import React, { useEffect, useState } from 'react';
import './style.scss';
import { RotateIcon } from '../../index';
import classNames from '../../../common/classNames';

function RotatePhone() {
  const [isTurn, setIsTurn] = useState(false);
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  function resize() {
    if (window.innerWidth < window.innerHeight) {
      setIsTurn(true);
    } else {
      setIsTurn(false);
    }
  }

  if (!isTurn) {
    return;
  }

  return (
    <div className="rotate-container">
      <RotateIcon className="rotate-container_icon" />
      <div className="rotate-container_info">Пожалуйста, переверни меня</div>
    </div>
  );
}

export default RotatePhone;
