import React from 'react';
import './style.scss';
import { TextButton } from '../../index';
import classNames from '../../../common/classNames';

function GameCard({ title, access, contents }) {
  return (
    <div
      className={classNames({
        'game-card': true,
        [`game-card--blocked`]: !access,
      })}
    >
      <span>{title}</span>
      {contents.map((content, index) => (
        <TextButton key={index} modifier={content.modifier} onClick={content.callBack}>
          {content.title}
        </TextButton>
      ))}
    </div>
  );
}

export default GameCard;
