import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';

/**
 * @param title - Текст для компоненты;
 * @param modifier - модификатор для компоненты:
 *  primary - background: green
 *  secondary - background: blue
 *  disabled - background: gray, pointer-events: none
 * @param customClass - Если необходим дополнительный класс
 * @param onClick - CallBack
 */

function TextButton({ children, modifier, customClass, onClick }) {
  return (
    <div
      className={classNames({
        notranslate: true,
        text_button: true,
        [`text_button--${modifier}`]: modifier,
        [`${customClass}`]: !!customClass,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default TextButton;
