import React from 'react';

export const LeftArrowIcon = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.08344 7.93363H16.5093V18.7934C16.5093 19.1352 16.6285 19.4216 16.8668 19.6525C17.1059 19.8842 17.4018 20 17.7547 20C18.1075 20 18.4035 19.8842 18.6426 19.6525C18.8809 19.4216 19 19.1352 19 18.7934V7.93363C19 7.26998 18.7559 6.70166 18.2677 6.22866C17.7804 5.75646 17.1943 5.52036 16.5093 5.52036H5.08344L8.66376 2.02112C8.89207 1.7999 9.01162 1.52318 9.02242 1.19095C9.03238 0.859528 8.91283 0.573152 8.66376 0.331825C8.43545 0.110608 8.14487 0 7.79203 0C7.43919 0 7.14861 0.110608 6.9203 0.331825L1.19178 5.88235C0.942717 6.12368 0.818182 6.40523 0.818182 6.727C0.818182 7.04877 0.942717 7.33032 1.19178 7.57164L6.9203 13.1222C7.16937 13.3635 7.45994 13.4789 7.79203 13.4685C8.12412 13.4588 8.41469 13.3333 8.66376 13.092C8.89207 12.8507 9.01162 12.5691 9.02242 12.2474C9.03238 11.9256 8.91283 11.644 8.66376 11.4027L5.08344 7.93363Z"
        fill="#3D3729"
      />
    </svg>
  );
};
