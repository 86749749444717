import React from 'react';
import { Route } from 'mobx-router';
import { Game, Launcher } from './pages';
import { LocalStorage } from './common/localStorage';
import RootStore from './store';
import { PLATFORM_URLS } from './common/platformUrls';

const routes = {
  init: new Route({
    path: '/play/:level',
    component: <div>ИНИЦИАЛИЗАЦИЯ</div>,
    onEnter: (route, params) => {
      LocalStorage.LEVEL = params.level;
      RootStore.appStore.getUserProgress();
      if (LocalStorage.LEVEL) {
        RootStore.router.goTo(routes.game, {});
      } else {
        RootStore.router.goTo(routes.launcher, {});
      }
    },
  }),
  accessDenied: new Route({
    path: '/access-denied',
    component: <div></div>,
    onEnter: (route, params, s, queryParams) => {
      debugger;
      location.href = PLATFORM_URLS.CLIENT;
    },
  }),
  launcher: new Route({
    path: '/launcher',
    component: <Launcher />,
    onEnter: () => {
      if (!LocalStorage.USER_TOKEN) {
        RootStore.router.goTo(routes.accessDenied);
      }
      RootStore.appStore.resetState();
      LocalStorage.LEVEL = '';
      LocalStorage.TUTORIAL_LAUNCHED = '';
      RootStore.appStore.getUserProgress();
    },
  }),
  game: new Route({
    path: '/',
    component: <Game />,
    onEnter: () => {
      if (!LocalStorage.LEVEL) {
        RootStore.router.goTo(routes.launcher, {});
      } else {
        RootStore.appStore.getUserProgress();
        RootStore.appStore.levelRestart(LocalStorage.LEVEL);
      }
    },
  }),
};

export default routes;
