import React from 'react';
import ReactModal from 'react-modal';
import classNames from '../../../common/classNames';
import { BUTTON_MODIFICATIONS, MODAL_SIZE } from '../../../common/constants';
import { ExitIcon, IconButton } from '../../index';
import './style.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Modal({
  isOpen,
  fullWidth,
  isVisible = true,
  header,
  title,
  media,
  children,
  footer,
  onClose,
  shouldCloseOnOverlayClick,
  size = MODAL_SIZE.DEFAULT,
  customClass,
  style = {},
  exitIcon = false,
  shouldCloseOnEsc = true,
  isLoadingMedia,
  ...rest
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      parentSelector={() => document.querySelectorAll('#appLayout')[0]}
      style={{
        overlay: { opacity: isVisible ? 1 : 0, pointerEvents: isVisible ? 'all' : 'none', ...style },
      }}
      overlayClassName="modal-overlay"
      className={classNames({
        modal: true,
        [`modal--size-${size}`]: size,
        [`modal--${customClass}`]: !!customClass,
      })}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      {...rest}
    >
      <div className="modal_container">
        {header && <div className="modal_header">{header}</div>}
        {exitIcon && (
          <IconButton customClass="modal_title-exit" modifier={BUTTON_MODIFICATIONS.SECONDARY} onClick={onClose}>
            <ExitIcon />
          </IconButton>
        )}

        <div className="modal_body">
          {media && (
            <div className="modal_media">
              {isLoadingMedia && (
                <SkeletonTheme baseColor="#DDDDDD" highlightColor="#FFFFFF" borderRadius="10px" duration={5}>
                  <Skeleton className="wrapper-static" />
                </SkeletonTheme>
              )}
              {media}
            </div>
          )}
          <div className="modal_body-content">
            {children || (
              <SkeletonTheme baseColor="#DDDDDD" highlightColor="#FFFFFF" borderRadius="10px" duration={5}>
                <Skeleton className="wrapper-static" />
              </SkeletonTheme>
            )}
          </div>
        </div>
        {footer && <div className="modal_actions">{footer}</div>}
      </div>
    </ReactModal>
  );
}

export default Modal;
