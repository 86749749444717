import React from 'react';

export const RightArrowIcon = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9166 7.93363H2.49066V18.7934C2.49066 19.1352 2.37152 19.4216 2.13325 19.6525C1.89415 19.8842 1.59817 20 1.24533 20C0.892487 20 0.596513 19.8842 0.35741 19.6525C0.119137 19.4216 0 19.1352 0 18.7934V7.93363C0 7.26998 0.244084 6.70166 0.732254 6.22866C1.21959 5.75646 1.80573 5.52036 2.49066 5.52036H13.9166L10.3362 2.02112C10.1079 1.7999 9.98838 1.52318 9.97758 1.19095C9.96762 0.859528 10.0872 0.573152 10.3362 0.331825C10.5645 0.110608 10.8551 0 11.208 0C11.5608 0 11.8514 0.110608 12.0797 0.331825L17.8082 5.88235C18.0573 6.12368 18.1818 6.40523 18.1818 6.727C18.1818 7.04877 18.0573 7.33032 17.8082 7.57164L12.0797 13.1222C11.8306 13.3635 11.5401 13.4789 11.208 13.4685C10.8759 13.4588 10.5853 13.3333 10.3362 13.092C10.1079 12.8507 9.98838 12.5691 9.97758 12.2474C9.96762 11.9256 10.0872 11.644 10.3362 11.4027L13.9166 7.93363Z"
        fill="#3D3729"
      />
    </svg>
  );
};
