import React, { Suspense, useRef, useMemo } from 'react';
import { useFBX } from '@react-three/drei';

export function Ladder({ object }) {
  const { x, z, floor } = object;
  let ladderPath = '/models/common/ladder/Ladder_Small.fbx';
  let scale = 0.0025;
  if (floor === 2) {
    ladderPath = '/models/common/ladder/Ladder_Medium.fbx';
    scale = 0.0026;
  } else if (floor === 3) {
    ladderPath = '/models/common/ladder/Ladder_Long.fbx';
    scale = 0.0027;
  }
  const ref = useRef();
  const ladderFBX = useFBX(ladderPath);
  const cloneLadder = useMemo(() => ladderFBX.clone(), [ladderFBX]);

  return (
    <group ref={ref} scale={scale} position={[x, 0, z + 0.52]}>
      <Suspense fallback={null}>
        <primitive object={cloneLadder} castShadow receiveShadow />
      </Suspense>
    </group>
  );
}
