import React, { Suspense, useRef } from 'react';
import { useFBX } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useControls } from '../../hooks/useControls';

const speed = {
  moving: 0.2,
  rotation: 0.5,
};

export function InteractionObject({ object }) {
  useControls();
  const ref = useRef();
  const modelFBX = useFBX(object.path);
  const position = object.position;
  const rotation = object.rotation;
  const scale = object.scale;

  modelFBX.children.forEach((el) => {
    el.castShadow = true;
    el.receiveShadow = true;
    el.material.shininess = 0;
  });

  useFrame(() => {
    const character = ref.current;
    character.position.x = THREE.MathUtils.lerp(character.position.x, position.x, speed.moving);
    character.position.y = THREE.MathUtils.lerp(character.position.y, position.y, speed.moving);
    character.position.z = THREE.MathUtils.lerp(character.position.z, position.z, speed.moving);
    character.rotation.y = THREE.MathUtils.lerp(character.rotation.y, rotation.y, speed.rotation);
  }, 0);

  return (
    <group ref={ref} scale={scale} position={[position.x, position.y, position.z]}>
      <Suspense fallback={null}>
        <primitive object={modelFBX} />
      </Suspense>
    </group>
  );
}
