import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';
import { BUTTON_MODIFICATIONS } from '../../../common/constants';

/**
 *
 * @param children - Компонент иконки
 * @param modifier - модификатор для компоненты:
 *  primary - background: blue
 *  secondary - background: green
 *  disabled - background: gray, pointer-events: none
 * @param customClass - Если необходим дополнительный класс
 * @param onClick - CallBack
 */

function IconButton({ children, modifier = BUTTON_MODIFICATIONS.PRIMARY, customClass, onClick }) {
  return (
    <div
      className={classNames({
        icon_button: true,
        [`icon_button--${modifier}`]: modifier,
        [`${customClass}`]: !!customClass,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default IconButton;
