import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import { MODEL } from '../../LevelParameters/LevelParametersAbstract';

export default class TheCommandCraneLeft extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isIntersectionWithoutLoaded({ buildings, hook }) {
    const intersection = buildings.find(
      (building) => building.position.x === hook.position.x - 3 && building.position.y === hook.position.y - 1,
    );
    if (intersection) {
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE_WITHOUT_CARGO,
        delay: 800,
      });
    }
    return !!intersection;
  }

  isIntersectionWithLoaded({ buildings, buildingWithHook }) {
    const intersection = buildings.find(
      (building) =>
        building.block_type === MODEL.BUILDING &&
        building.position.y === Math.floor(buildingWithHook.position.y) &&
        building.position.x === buildingWithHook.position.x - 3,
    );

    if (intersection) {
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE,
        delay: 800,
      });
    }
    return !!intersection;
  }

  run() {
    const { buildings, buildingWithHook, hook } = super.run();
    const interpolateX = hook.position.x - 3;
    if (!RootStore.appStore._levelParameters.isBuildingHooked) {
      if (this.isIntersectionWithoutLoaded({ buildings: buildings, hook: hook })) {
        RootStore.appStore._levelParameters.disableCommandExecution();
        hook.position.x -= 2;
        return;
      }
    } else {
      if (this.isIntersectionWithLoaded({ buildings: buildings, buildingWithHook: buildingWithHook })) {
        RootStore.appStore._levelParameters.disableCommandExecution();
        hook.position.x -= 1.2;
        buildingWithHook.position.x -= 1.2;
        return;
      }
    }

    hook.position.x -= 3;
    if (RootStore.appStore._levelParameters.isBuildingHooked) {
      buildingWithHook.position.x -= 3;
    }

    // Дальше 2 (влево) кран двигаться не может
    if (interpolateX < 2) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      const notificationType = RootStore.appStore._levelParameters.isBuildingHooked
        ? NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE
        : NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE_WITHOUT_CARGO;
      RootStore.appStore._levelParameters.notifications.open({
        type: notificationType,
        delay: 800,
      });
    }
  }
}
