import React, { Suspense, useMemo } from 'react';
import { useFBX } from '@react-three/drei';

const boxesGeneration = ({ obstacleFBX, position, rotation }) => {
  const boxes = [];
  for (let i = 0; i <= 2; i++) {
    const newPosition = {
      x: i === 0 ? position.x - 0.17 : i === 1 ? position.x + 0.17 : position.x,
      y: i === 2 ? position.y + 0.25 : position.y,
      z: i === 2 ? position.z + 0.05 : position.z,
    };
    const newRotation = {
      x: rotation.x,
      y: i === 0 ? Math.PI / 10 : i === 2 ? (35 * Math.PI) / 18 : rotation.y,
      z: rotation.z,
    };
    boxes.push({
      object: useMemo(() => obstacleFBX.clone(), [obstacleFBX]),
      position: newPosition,
      rotation: newRotation,
    });
  }
  return boxes;
};

export function Box({ object }) {
  const obstacleFBX = useFBX(object.path);
  const position = object.position;
  const rotation = object.rotation;
  const scale = object.scale;

  obstacleFBX.children[0].castShadow = true;
  obstacleFBX.children[0].receiveShadow = true;
  obstacleFBX.children[0].material.shininess = 0;

  const boxes = boxesGeneration({ obstacleFBX, position, rotation });

  return (
    <group>
      <Suspense fallback={null}>
        {boxes.map((box, index) => (
          <primitive
            key={index}
            scale={scale}
            position={[box.position.x, box.position.y, box.position.z]}
            rotation={[box.rotation.x, box.rotation.y, box.rotation.z]}
            object={box.object}
          />
        ))}
      </Suspense>
    </group>
  );
}
