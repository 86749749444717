import React, { Suspense, useRef } from 'react';
import { useFBX } from '@react-three/drei';

export function Checkpoint({ object }) {
  const ref = useRef();
  const modelFBX = useFBX(object.path);
  const position = object.position;
  const scale = object.scale;

  modelFBX.children.forEach((el) => {
    el.castShadow = true;
    el.receiveShadow = true;
    el.shininess = true;
  });

  return (
    <group ref={ref} scale={scale} position={[position.x, position.y + 0.01, position.z]}>
      <Suspense fallback={null}>
        <primitive object={modelFBX} />
      </Suspense>
    </group>
  );
}
