import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';

export default class TheCommandHook extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isCheckBuildingHook({ basic, buildingWithHook, hook, level }) {
    if (level === 3) {
      // +1 т.к. объект взаимодействия на 1 координату выше этажа с петлей (для 3 уровня)
      const checkBuildingHook =
        basic.position.x === buildingWithHook.position.x && basic.position.y === buildingWithHook.position.y + 1;
      if (!checkBuildingHook) {
        RootStore.appStore._levelParameters.notifications.open({
          type: NOTIFICATION_TYPES.BUILDING_HAS_NO_HOOK,
          delay: 500,
        });
      }
      return checkBuildingHook;
    } else {
      const checkBuildingHook =
        buildingWithHook.position.x === hook.position.x &&
        buildingWithHook.position.y + 1 === Math.floor(hook.position.y - 1);
      if (!checkBuildingHook) {
        RootStore.appStore._levelParameters.notifications.open({
          type: NOTIFICATION_TYPES.BUILDING_HAS_NO_HOOK,
          delay: 500,
        });
      }
      return checkBuildingHook;
    }
  }

  run() {
    const level = RootStore.appStore.currentLevel;
    const { basic, buildingWithHook, hook } = super.run();
    if (
      !this.isCheckBuildingHook({
        basic: basic,
        buildingWithHook: buildingWithHook,
        hook: hook,
        level: level,
      })
    ) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    }
    if (level === 3) {
      hook.position.y = +(hook.position.y - 0.45).toFixed(2);
      hook.rotation.y = 0;
      setTimeout(() => {
        buildingWithHook.position.y = +(buildingWithHook.position.y + 0.1).toFixed(2);
        hook.position.y = +(hook.position.y + 0.1).toFixed(2);
        basic.position.y = +(basic.position.y + 0.1).toFixed(2);
      }, 600);
    } else {
      hook.rotation.y = 2 * Math.PI;
      hook.position.y = +(hook.position.y - 0.45).toFixed(2);
      setTimeout(() => {
        buildingWithHook.position.y = +(buildingWithHook.position.y + 0.1).toFixed(2);
        hook.position.y = +(hook.position.y + 0.1).toFixed(2);
      }, 600);
    }
    RootStore.appStore._levelParameters.isBuildingHooked = true;
  }
}
