import React, { Fragment, useState } from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { Modal, TextButton } from '../../../index';
import { BUTTON_MODIFICATIONS, MODAL_SIZE } from '../../../../common/constants';
import { BUTTON_EVENT, BUTTON_TEXT } from '../../../../store/Notifications/Notifications';
import 'react-loading-skeleton/dist/skeleton.css';

function TutorialModal() {
  const { appStore } = RootStore;
  const [isLoadingMedia, setIsLoadingMedia] = useState(true);
  if (!appStore._levelParameters?.tutorial?.data) {
    return;
  }
  const data = appStore._levelParameters.tutorial.data;
  const { title, media, content, button, callback } = data;

  return (
    <Modal
      isOpen={!!data}
      onClose={() => {
        if (button.event === BUTTON_EVENT.START && button.isVisible) {
          callback(BUTTON_EVENT.START);
        }
      }}
      exitIcon={button.isVisible && button.event === BUTTON_EVENT.START}
      shouldCloseOnEsc={button.isVisible && button.event === BUTTON_EVENT.START}
      size={media && MODAL_SIZE.BIG}
      customClass="tutorial"
      media={
        !!media && (
          <Fragment>
            {media.type === 'video' ? (
              <video
                autoPlay
                loop
                playsInline
                key={media.path}
                src={media.path}
                style={{ display: isLoadingMedia ? 'none' : undefined }}
                onCanPlayThrough={() => setIsLoadingMedia(false)}
              />
            ) : (
              <img
                key={media.path}
                src={media.path}
                alt="media"
                style={{ display: isLoadingMedia ? 'none' : undefined }}
                onLoad={() => setIsLoadingMedia(false)}
              />
            )}
          </Fragment>
        )
      }
      isLoadingMedia={isLoadingMedia}
      footer={
        <TextButton
          modifier={
            !button.isVisible
              ? BUTTON_MODIFICATIONS.DISABLED
              : button.event === BUTTON_EVENT.START
              ? BUTTON_MODIFICATIONS.POSITIVE
              : BUTTON_MODIFICATIONS.PRIMARY
          }
          onClick={() => {
            callback(button.event);
            setIsLoadingMedia(true);
          }}
        >
          {BUTTON_TEXT[button.event]}
        </TextButton>
      }
    >
      {title && (
        <div className="modal_body-header">
          <span>{title}</span>
        </div>
      )}
      <div className="modal_body-info">{content}</div>
    </Modal>
  );
}

export default observer(TutorialModal);
