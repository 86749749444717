import Binder from '../common/binder';
import { makeAutoObservable } from 'mobx';
import { TOGGLE_TYPE } from '../common/constants';
import routes from '../routes';
import { LocalStorage } from '../common/localStorage';
import api from '../api';
import FirstLevel from './LevelParameters/levels/FirstLevel';
import SecondLevel from './LevelParameters/levels/SecondLevel';
import ThirdLevel from './LevelParameters/levels/ThirdLevel';
import FourthLevel from './LevelParameters/levels/FourthLevel';
import { PLATFORM_URLS } from '../common/platformUrls';
import { backgroundMusic } from '../ui';
import _ from 'lodash';
import { MODEL } from './LevelParameters/LevelParametersAbstract';

const fullscreen = require('fullscreen');

export default class AppStore {
  progress = 0;
  musicsOn = false;
  error = null;
  backgroundMusic = new Audio(backgroundMusic);
  resetState() {
    this.currentLevel = 0;
    this._levelParameters = null;
    this.isCanvasLoaded = false;

    this.settingModalFlag = false;
    this.soundsOn = false;
  }
  constructor(router) {
    this.resetState();
    Binder.bind(this, AppStore);
    this.router = router;
    this.fullScreenOn = false;
    this.fullscreenNotSupported = false;

    makeAutoObservable(this);
  }

  setCurrentLevel(level) {
    this.currentLevel = parseInt(level);
  }

  setCanvasLoaded(event) {
    this.isCanvasLoaded = event;
  }

  get getLevelParameters() {
    return this._levelParameters;
  }

  async levelStart({ level, isForce = false, isTutorial = false }) {
    if (isTutorial) {
      LocalStorage.TUTORIAL_LAUNCHED = true;
    }
    if (isForce) {
      await this.router.goTo(routes.init, { level });
      return;
    }
    this.resetState();
    this.setCurrentLevel(LocalStorage.LEVEL);
    let result = null;
    await api.common
      .generateMap(level)
      .then((response) => {
        result = response.message;
      })
      .catch((error) => {
        const { data, result, code } = error;
        console.error('REQUEST ERROR:', data.url, result.message, code);
        this.openErrorModal(code);
      });

    if (!result) {
      return;
    }

    if (this.currentLevel === 1) {
      this._levelParameters = new FirstLevel(result.blocks);
    } else if (this.currentLevel === 2) {
      this._levelParameters = new SecondLevel(result.blocks);
    } else if (this.currentLevel === 3) {
      this._levelParameters = new ThirdLevel(result.blocks);
    } else if (this.currentLevel === 4) {
      this._levelParameters = new FourthLevel(result.blocks);
    }

    LocalStorage.MAP_UID = result.map_uuid;
    this._levelParameters.init();
  }

  async getUserProgress() {
    await api.common
      .getUserProgress()
      .then((response) => {
        this.progress = !response.chapters.length ? 0 : Math.max(...response.chapters);
      })
      .catch(() => {
        this.exitFromGame();
      });
  }

  async fail() {
    const objects = {
      basic: _.find(this.getLevelParameters.models, { block_type: MODEL.BASIC })?.position,
      hook: _.find(this.getLevelParameters.models, { block_type: MODEL.BUILDING_WITH_HOOK })?.position,
    };
    if (this.currentLevel === 4) {
      await this.setUserProgress(objects.hook, false);
    } else {
      await this.setUserProgress(objects.basic, false);
    }
  }

  async setUserProgress(coordinates, passed) {
    await api.common
      .setUserProgress(LocalStorage.LEVEL, LocalStorage.MAP_UID, coordinates, passed)
      .then((response) => {
        this.progress = Math.max(...response.chapters);
      })
      .catch((error) => {
        const { data, result, code } = error;
        console.error('REQUEST ERROR:', data.url, result.message, code);
        this.openErrorModal(code);
      });
  }

  levelRestart(level) {
    this.levelStart({ level: level });
  }

  nextLevel() {
    this.currentLevel++;
    LocalStorage.LEVEL = this.currentLevel;
    this.levelStart({ level: this.currentLevel, isTutorial: true });
  }

  closeLevel() {
    LocalStorage.LEVEL = '';
    LocalStorage.MAP_UID = '';
    this.resetState();
    this.router.goTo(routes.launcher, {});
  }

  exitFromGame() {
    LocalStorage.USER_TOKEN = '';
    LocalStorage.REFRESH_TOKEN = '';
    location.href = PLATFORM_URLS.CLIENT;
  }

  openSettingModal() {
    this.checkFullscreen();
    this.settingModalFlag = true;
  }

  closeSettingModal() {
    this.settingModalFlag = false;
  }

  openErrorModal(code) {
    this.error = code;
  }

  closeErrorModal() {
    this.error = null;
    this.closeLevel();
  }

  toggleMusics() {
    this.backgroundMusic.loop = true;
    this.musicsOn = !this.musicsOn;
    if (this.musicsOn) {
      this.backgroundMusic.play();
    } else {
      this.backgroundMusic.pause();
    }
  }

  toggleSounds() {
    this.soundsOn = !this.soundsOn;
  }

  get [`checkToggle${TOGGLE_TYPE.SOUND}`]() {
    return this.soundsOn;
  }

  get [`checkToggle${TOGGLE_TYPE.MUSIC}`]() {
    return this.musicsOn;
  }

  get [`checkToggle${TOGGLE_TYPE.FULLSCREEN}`]() {
    return this.fullScreenOn;
  }

  checkFullscreen() {
    if (require('fullscreen').available()) {
      this.fullScreenOn = !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
    }
  }

  toggleFullscreen() {
    if (require('fullscreen').available()) {
      const fs = fullscreen(document.body);
      if (this.fullScreenOn) {
        fs.release();
        this.fullScreenOn = false;
      } else {
        fs.request();
        this.fullScreenOn = true;
      }
    } else {
      this.fullscreenNotSupported = true;
    }
    this.closeSettingModal();
  }

  hideFullScreenNotSupportedModal() {
    this.fullscreenNotSupported = false;
  }
}
