import React, { Suspense, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import {
  // OrbitControls,
  PerformanceMonitor,
  // Stats,
  AdaptiveDpr,
  Loader,
  useProgress,
} from '@react-three/drei';
import SceneObjects from './SceneObjects/SceneObjects';
import './style.scss';
import * as THREE from 'three';
import { useControls } from 'leva';
import RootStore from '../../store';

const loaderStyles = {
  container: {
    background: '#0c0c0c',
    opacity: 0.8,
    zIndex: 1,
  },
  inner: {
    background: '#0C0C0C',
  },
  bar: {
    background: '#35E522',
  },
  data: {
    fontFamily: 'Balsamiq',
    color: '#FFF9E0',
  },
};

function Lights() {
  const ambientRef = useRef();
  const directionalRef = useRef();
  const pointRef = useRef();
  const spotRef = useRef();

  useControls('Ambient Light', {
    visible: {
      value: false,
      onChange: (v) => {
        ambientRef.current.visible = v;
      },
    },
    color: {
      value: 'white',
      onChange: (v) => {
        ambientRef.current.color = new THREE.Color(v);
      },
    },
  });
  useControls('Directional Light', {
    visible: {
      value: false,
      onChange: (v) => {
        directionalRef.current.visible = v;
      },
    },
    position: {
      x: 1,
      y: 1,
      z: 1,
      onChange: (v) => {
        directionalRef.current.position.copy(v);
      },
    },
    color: {
      value: 'white',
      onChange: (v) => {
        directionalRef.current.color = new THREE.Color(v);
      },
    },
  });
  useControls('Point Light', {
    visible: {
      value: false,
      onChange: (v) => {
        pointRef.current.visible = v;
      },
    },
    position: {
      x: 2,
      y: 0,
      z: 0,
      onChange: (v) => {
        pointRef.current.position.copy(v);
      },
    },
    color: {
      value: 'white',
      onChange: (v) => {
        pointRef.current.color = new THREE.Color(v);
      },
    },
  });
  useControls('Spot Light', {
    visible: {
      value: false,
      onChange: (v) => {
        spotRef.current.visible = v;
      },
    },
    position: {
      x: 3,
      y: 2.5,
      z: 1,
      onChange: (v) => {
        spotRef.current.position.copy(v);
      },
    },
    color: {
      value: 'white',
      onChange: (v) => {
        spotRef.current.color = new THREE.Color(v);
      },
    },
  });
  return (
    <>
      <ambientLight ref={ambientRef} />
      <directionalLight ref={directionalRef} />
      <pointLight ref={pointRef} />
      <spotLight ref={spotRef} />
    </>
  );
}

function Scene() {
  const { appStore } = RootStore;
  const [dpr, setDpr] = useState(1);
  useProgress((state) => {
    // из хука useProgress - initial state для active === false
    if (!state.active && state.progress === 100) {
      appStore.setCanvasLoaded(true);
    }
  });
  const aspectRatio = window.document.body.clientWidth / window.document.body.clientHeight;
  const zoomSettings = appStore._levelParameters.canvasPreset.camera.zoom;
  let zoom;

  if (aspectRatio < 1.5) {
    zoom = zoomSettings['min'];
  } else if (aspectRatio < 2) {
    zoom = zoomSettings['average'];
  } else {
    zoom = zoomSettings['max'];
  }

  const camera = {
    ...appStore._levelParameters.canvasPreset.camera,
    zoom: zoom,
  };

  return (
    <div className="canvas-container">
      <Loader
        containerStyles={loaderStyles.container} // Flex layout styles
        barStyles={loaderStyles.bar} // Loading-bar styles
        dataStyles={loaderStyles.data} // Text styles
        dataInterpolation={(p) => `Загрузка ${p.toFixed(2)}%`} // Text
        initialState={(active) => active}
      />
      <Canvas shadows dpr={dpr} camera={camera}>
        <Suspense fallback={null}>
          {/* -- Оптимизация -- */}
          <AdaptiveDpr pixelated />
          <PerformanceMonitor onChange={({ factor }) => setDpr(_.round(0.5 + 1.5 * factor, 1))}>
            {/*-- Для разработки -- */}
            {/*<OrbitControls />*/}
            {/* Для разработки */}
            {/* -- Свет -- */}
            <ambientLight intensity={0.5} />
            <pointLight castShadow intensity={0.1} position={[-10, 10, 5]} shadow-mapSize={[512, 512]} />
            <directionalLight castShadow position={[-10, 10, 5]} intensity={0.1} />
            {/* Свет */}

            {/* -- Объекты сцены -- */}
            <SceneObjects />
            {/* -- Объекты сцены -- */}

            {/* -- FPS for dev -- */}
            {/*<Stats className="canvas-container_stats" />*/}
            {/* -- FPS -- */}
          </PerformanceMonitor>
          {/* -- Оптимизация -- */}
        </Suspense>
      </Canvas>
    </div>
  );
}

export default Scene;
