import React, { Suspense, useRef } from 'react';
import { useFBX } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const speed = {
  moving: 0.2,
  rotation: 0.5,
};

export function HouseWithHook({ object }) {
  const ref = useRef();
  const path = object.path;
  const houseFBX = useFBX(path);
  houseFBX.children[0].castShadow = true;
  houseFBX.children[0].receiveShadow = true;
  houseFBX.children[0].material.shininess = 0;
  const position = object.position;
  const rotation = object.rotation;
  const scale = object.scale;

  useFrame(() => {
    const building = ref.current;
    building.position.x = THREE.MathUtils.lerp(building.position.x, position.x, speed.moving);
    building.position.y = THREE.MathUtils.lerp(building.position.y, position.y, speed.moving);
    building.position.z = THREE.MathUtils.lerp(building.position.z, position.z, speed.moving);
    building.rotation.y = THREE.MathUtils.lerp(building.rotation.y, rotation.y, speed.moving);
  }, 0);

  return (
    <group ref={ref} scale={scale} position={[position.x, position.y, position.z]} rotation={[0, 0, 0]}>
      <Suspense fallback={null}>
        <primitive object={houseFBX} castShadow receiveShadow />
      </Suspense>
    </group>
  );
}
