import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import { MODEL } from '../../LevelParameters/LevelParametersAbstract';

export default class TheCommandLower extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isIntersectionWithoutLoaded({ buildings, hook }) {
    const intersection = buildings.find(
      (building) => building.position.x === hook.position.x && building.position.y === hook.position.y - 2,
    );
    if (intersection) {
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE,
        delay: 800,
      });
    }
    return !!intersection;
  }

  isIntersectionWithLoaded({ buildings, buildingWithHook }) {
    const intersection = buildings.find(
      (building) =>
        building.block_type === MODEL.BUILDING &&
        building.position.x === buildingWithHook.position.x &&
        building.position.y === Math.floor(buildingWithHook.position.y - 1),
    );

    if (intersection) {
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE,
        delay: 800,
      });
    }
    return !!intersection;
  }

  run() {
    const { buildings, buildingWithHook, hook } = super.run();

    const interpolateHookY = +(hook.position.y - 1).toFixed(2);
    const interpolateBuildingY = +(buildingWithHook.position.y - 1).toFixed(2);

    if (!RootStore.appStore._levelParameters.isBuildingHooked) {
      if (this.isIntersectionWithoutLoaded({ buildings: buildings, hook: hook })) {
        RootStore.appStore._levelParameters.disableCommandExecution();
      } else if (interpolateHookY <= 1) {
        RootStore.appStore._levelParameters.disableCommandExecution();
        RootStore.appStore._levelParameters.notifications.open({ type: NOTIFICATION_TYPES.CANT_GO_LOWER, delay: 800 });
      }
    } else {
      if (this.isIntersectionWithLoaded({ buildings: buildings, buildingWithHook: buildingWithHook })) {
        buildingWithHook.position.y = interpolateBuildingY + 0.7;
        hook.position.y = interpolateHookY + 0.7;
        RootStore.appStore._levelParameters.disableCommandExecution();
        return;
      } else if (hook.position.y <= 2) {
        buildingWithHook.position.y = interpolateBuildingY + 0.7;
        hook.position.y = interpolateHookY + 0.7;
        RootStore.appStore._levelParameters.disableCommandExecution();
        RootStore.appStore._levelParameters.notifications.open({ type: NOTIFICATION_TYPES.CANT_GO_LOWER, delay: 800 });
        return;
      }
    }

    hook.position.y = interpolateHookY;
    if (RootStore.appStore._levelParameters.isBuildingHooked) {
      buildingWithHook.position.y = interpolateBuildingY;
    }
  }
}
