import React from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { Modal, TextButton } from '../../../index';
import { BUTTON_MODIFICATIONS } from '../../../../common/constants';

function FullScreenNotSupportedModal() {
  const { appStore } = RootStore;
  if (!appStore.fullscreenNotSupported) {
    return;
  }

  return (
    <Modal
      isOpen={appStore.fullscreenNotSupported}
      onClose={() => appStore.hideFullScreenNotSupportedModal()}
      exitIcon
      footer={
        <TextButton modifier={BUTTON_MODIFICATIONS.PRIMARY} onClick={() => appStore.hideFullScreenNotSupportedModal()}>
          Понятно
        </TextButton>
      }
    >
      <div className="modal_body-info">
        <span>
          К сожалению, данная возможность не поддерживается на твоём устройстве, попробуйте встроенные возможности
          вашего браузера
        </span>
      </div>
    </Modal>
  );
}

export default observer(FullScreenNotSupportedModal);
