import ApiService from '../apiService';
import { LocalStorage } from '../../common/localStorage';

class Common {
  constructor() {
    this.apiService = new ApiService('/api', (method, url, params, body) => {
      return {
        method,
        url,
        params,
        body,
        headers: { jwt: LocalStorage.USER_TOKEN },
      };
    });
  }

  async generateMap(level) {
    return this.apiService.post('generate_map', {}, { chapter_num: +level });
  }

  async getUserProgress() {
    return this.apiService.get('notifications/get_chapter_state');
  }

  async setUserProgress(level, mapUid, coordinates, passed = true) {
    return this.apiService.post(
      'notifications/set_chapter_state',
      {},
      { chapter_num: +level, map_uuid: mapUid, coordinates: coordinates, passed: passed },
    );
  }
}

export default new Common();
