import calculateAngleRotation from '../../common/calculateAngleRotation';
import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import Tutorial from '../Tutorial/Tutorial';
import Notifications, { NOTIFICATION_TYPES } from '../Notifications/Notifications';
import RootStore from '../index';
import { GAME_BUTTON_MODIFICATIONS } from '../../common/constants';

export const MODEL = {
  MATRIX: 0,
  OBSTACLE: 1,
  BASIC: 2,
  CHECKPOINT: 3,
  SCENE_EDGE: 4,
  BUILDING: 5, // здание (дефолт здание)
  BUILDING_WITH_HOOK: 6, // здание груз (которое можем зацепить)
  HOOK: 7, // Крюк

  STAGE_ENVIRONMENT: 10,
  ENVIRONMENT: 11,
};

export default class LevelParametersAbstract {
  #abstractModels;
  #modelSettings;
  #commandsPreset;
  constructor({ modelSettings, commandsPreset = [], tutorialPreset = [], notificationsPreset = [] }) {
    this.#abstractModels = [];
    this.#modelSettings = modelSettings;
    this.#commandsPreset = commandsPreset;

    this.models = [];
    this.commands = [];
    this.stack = [];
    this.currentCommandIndex = 0;
    this.commandExecutionPermission = true;
    this.commandStarted = false;
    this.tutorial = new Tutorial(tutorialPreset);
    this.notifications = new Notifications(notificationsPreset);
    makeObservable(this, {
      commands: observable,
      stack: observable,
      commandStarted: observable,
      models: observable,
    });
  }

  init() {
    this.setCommands();
    this.setModels();
  }

  addCommandInStack(command) {
    if (this.tutorial.data || this.notifications.data || !this.getCommandExecutionPermission || this.isCommandStarted) {
      return;
    }
    this.stack.push(command.clone());
  }

  removeCommandFromStack(command) {
    if (this.isCommandStarted) {
      return;
    }
    _.remove(this.stack, { key: command.key });
  }

  clearCommand() {
    this.stack = [];
  }

  runningCommand() {
    if (!this.stack.length) {
      return;
    }
    this.startCommand();
    this.currentCommandIndex = 0;
    this.executeCommandStack();
  }

  executeCommandStack() {
    return this.stack[this.currentCommandIndex];
  }

  startCommand() {
    this.commandStarted = true;
  }

  endCommand() {
    this.commandStarted = false;
  }

  endpointCheck() {
    const objects = {
      basic: _.find(this.models, { block_type: MODEL.BASIC })?.position || null,
      checkpoint: _.find(this.models, { block_type: MODEL.CHECKPOINT })?.position || null,
    };
    if (_.isEqual(objects.basic, objects.checkpoint)) {
      this.successfulExecution({ position: objects.basic });
      this.disableCommandExecution();
    }
  }

  successfulExecution({ position }) {
    RootStore.appStore.setUserProgress(position);
    this.notifications.open({ type: NOTIFICATION_TYPES.CHECKPOINT, delay: 500 });
  }

  reloadCommand({ delay = 0, command = null }) {
    if (!command || !this.getCommandExecutionPermission) {
      return;
    }
    setTimeout(() => {
      command.modifier = GAME_BUTTON_MODIFICATIONS.DONE;
      this.currentCommandIndex++;
      this.executeCommandStack();
    }, delay);
  }

  get isCommandStarted() {
    return this.commandStarted;
  }

  disableCommandExecution() {
    this.commandExecutionPermission = false;
  }

  turnOnCommandExecution() {
    this.commandExecutionPermission = true;
  }

  get getCommandExecutionPermission() {
    return this.commandExecutionPermission;
  }

  get getStack() {
    return this.stack;
  }

  get isDisabledBlock() {
    return this.getStack.length;
  }

  get isDisabledRun() {
    return !this.getStack.length || this.isCommandStarted;
  }

  modelBuilder(model) {
    return {
      key: `${Math.random()}_${model.block_type}`,
      path: this.#modelSettings[model.block_type].path,
      block_type: model.block_type,
      position: { x: model.x, y: model.y, z: model.z },
      rotation: { x: 0, y: model.direction ? calculateAngleRotation(model.direction) : 0, z: 0 },
      scale: this.#modelSettings[model.block_type].scale,
    };
  }

  setModels(models) {
    this.#abstractModels = models;

    this.models = this.#abstractModels.map((model) => {
      return this.modelBuilder(model);
    });
  }

  setCommands() {
    this.commands = Object.values(this.#commandsPreset).map((command) => {
      return command.build();
    });
  }

  get getModels() {
    return this.models;
  }

  get getCommands() {
    return this.commands;
  }

  get getHotkeysCommands() {
    const hotkeys = {};
    const commands = {};
    this.commands.forEach((command) => {
      hotkeys[command.hotkey] = false;
      commands[command.hotkey] = command;
    });
    return { hotkeys, commands };
  }
}
