import React, { Fragment } from 'react';
import _ from 'lodash';
import RootStore from '../../index';
import LevelParametersAbstract, { MODEL } from '../LevelParametersAbstract';
import { CONTROL_COMMANDS } from '../../ControlCommands/CommandAbstract';
import { makeObservable, observable } from 'mobx';
import TheCommandHook from '../../ControlCommands/commands/TheCommandHook';
import TheCommandUnhook from '../../ControlCommands/commands/TheCommandUnhook';
import TheCommandCraneLeft from '../../ControlCommands/commands/TheCommandCraneLeft';
import TheCommandCraneRight from '../../ControlCommands/commands/TheCommandCraneRight';
import TheCommandRaise from '../../ControlCommands/commands/TheCommandRaise';
import TheCommandLower from '../../ControlCommands/commands/TheCommandLower';
import { BUTTON_MODIFICATIONS, GAME_BUTTON_MODIFICATIONS } from '../../../common/constants';
import { BUTTON_EVENT, NOTIFICATION_TYPES } from '../../Notifications/Notifications';

import {
  endGame,
  fail_3,
  fail_8,
  fail_9,
  fail_10,
  fail_11,
  level4_button_step3,
  level4_button_step4,
  level4_button_step5,
  level4_step1,
  level4_step2,
  level4_step3,
  level4_step4,
  level4_step5,
  level4_step6,
} from '../../../ui';

const tutorialPreset = {
  1: {
    title: 'Добро пожаловать на 4 уровень',
    media: { type: 'img', path: level4_step1 },
    content: (
      <Fragment>
        <span>Теперь кошечка управляет строительным краном</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  2: {
    title: 'Новые команды',
    media: { type: 'img', path: level4_step2 },
    content: (
      <Fragment>
        <span>Добавляются новые команды “Отцепить”, “Поднять” и “Опустить”</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  3: {
    title: 'Команда ”Отцепить”',
    media: { type: 'video', path: level4_step3 },
    content: (
      <Fragment>
        <span>Данная команда</span>
        <img src={level4_button_step3} alt="Отцепить" />
        <span>нужна, чтобы Вы могли отцепить груз от строительного крана</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  4: {
    title: 'Команда ”Поднять”',
    media: { type: 'video', path: level4_step4 },
    content: (
      <Fragment>
        <span>Кошечка при помощи</span>
        <img src={level4_button_step4} alt="Поднять" />
        <span>сможет поднимать строительный крюк вверх</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  5: {
    title: 'Команда ”Опустить”',
    media: { type: 'video', path: level4_step5 },
    content: (
      <Fragment>
        <span>Кошечка при помощи</span>
        <img src={level4_button_step5} alt="Опустить" />
        <span>сможет опускать строительный крюк вниз</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  6: {
    title: 'Проигрыш',
    media: { type: 'video', path: level4_step6 },
    content: (
      <Fragment>
        <span>
          Проиграть на четвёртом уровне возможно, если строительный кран врежется в здание, либо если груз упадет с
          большой высоты или если в конце последовательности действий груз не находится в конечной точке
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  7: {
    title: 'Задание',
    content: (
      <Fragment>
        <span>Необходимо перенести с помощью строительного крана строящийся объект с одного места в другое</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.START,
  },
};
const modelSettings = {
  [MODEL.ENVIRONMENT]: {
    scale: 0.004,
    path: '/models/common/environment/BackGround.fbx',
  },
  [MODEL.STAGE_ENVIRONMENT]: {
    scale: 0.0033,
    path: '/models/common/sceneEnvironment/Level_03.fbx',
  },
  [MODEL.CHECKPOINT]: {
    scale: 0.007,
    path: '/models/common/checkpoint/SignExit.fbx',
  },
  [MODEL.SCENE_EDGE]: {
    scale: 1,
    path: '',
  },
  [MODEL.BUILDING]: {
    scale: 0.00275,
    path: '/models/common/GreenHouse/HouseBlock_V1_Door.fbx',
  },
  [MODEL.BUILDING_WITH_HOOK]: {
    scale: 0.00275,
    path: '/models/common/GrayHouse/HouseGrayBlock_V3_Hook.fbx',
  },
  [MODEL.HOOK]: {
    scale: 0.2,
    path: '/models/common/crane/Hook11.fbx',
  },
};
const commandsPreset = {
  [CONTROL_COMMANDS.CRANE_LEFT]: new TheCommandCraneLeft(CONTROL_COMMANDS.CRANE_LEFT),
  [CONTROL_COMMANDS.CRANE_RIGHT]: new TheCommandCraneRight(CONTROL_COMMANDS.CRANE_RIGHT),
  [CONTROL_COMMANDS.RAISE]: new TheCommandRaise(CONTROL_COMMANDS.RAISE),
  [CONTROL_COMMANDS.LOWER]: new TheCommandLower(CONTROL_COMMANDS.LOWER),
  [CONTROL_COMMANDS.HOOK]: new TheCommandHook(CONTROL_COMMANDS.HOOK),
  [CONTROL_COMMANDS.UNHOOK]: new TheCommandUnhook(CONTROL_COMMANDS.UNHOOK),
};
const notificationsPreset = {
  [NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE]: {
    media: { type: 'video', path: fail_3 },
    content: <span>Вы столкнулись со зданием, попробуйте не создавать аварийных ситуаций, у Вас точно получится!</span>,
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE_WITHOUT_CARGO]: {
    media: { type: 'video', path: fail_10 },
    content: <span>Вы столкнулись со зданием, попробуйте не создавать аварийных ситуаций, у Вас точно получится!</span>,
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE]: {
    media: { type: 'video', path: fail_8 },
    content: (
      <span>
        Работая со сложной строительной техникой вам необходимо соблюдать установленные правила, старайтесь не выходить
        за пределы игрового поля.
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE_WITHOUT_CARGO]: {
    media: { type: 'video', path: fail_11 },
    content: (
      <span>
        Работая со сложной строительной техникой вам необходимо соблюдать установленные правила, старайтесь не выходить
        за пределы игрового поля.
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.FELL_OFF_BUILDING]: {
    media: { type: 'video', path: fail_9 },
    content: (
      <span>Сначала нужно поставить груз, а потом уже отцепить его от крана, попробуйте составить алгоритм снова!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.YOU_CANT_GO_HIGHER]: {
    content: (
      <span>Поднять крюк выше уже не получится, попробуйте составить алгоритм снова, у вас точно получится!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.CANT_GO_LOWER]: {
    content: (
      <span>Опустить крюк ниже уже не получится, попробуйте составить алгоритм снова, у Вас точно получится!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.BUILDING_HAS_NO_HOOK]: {
    content: (
      <span>Ваша цель находится в другом месте, попробуйте составить алгоритм снова, у Вас точно получится!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.BUILDING_NOT_HOOKED]: {
    content: (
      <span>Прежде чем отцепить груз от крана, необходимо его зацепить, попробуйте составить алгоритм снова!</span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.NOT_COMPLETED]: {
    content: (
      <span>
        Выполнены все заданные действия, но груз не доставлен на нужное место, попробуйте составить алгоритм снова, у
        Вас точно получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.CHECKPOINT]: {
    media: { type: 'video', path: endGame },
    content: <span>Вы отлично себя показали и справились со всеми заданиями! Будем ждать вас в Алабуге!</span>,
    buttons: [{ event: BUTTON_EVENT.EXIT, modifier: BUTTON_MODIFICATIONS.POSITIVE }],
  },
  [NOTIFICATION_TYPES.INVITATION]: {
    media: { type: 'video', path: endGame },
    content: (
      <span>
        Вы неплохо себя показали, попробуйте ещё раз, чтобы добиться лучшего результата! Будем ждать вас в Алабуге!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.EXIT, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
    ],
  },
};

export default class FourthLevel extends LevelParametersAbstract {
  #models;
  constructor(models) {
    super({ modelSettings, commandsPreset, tutorialPreset, notificationsPreset });
    this.#models = models;
    this.isBuildingHooked = false;
    this.canvasPreset = {
      camera: { position: [0, 8, 25], fov: 50, aspect: 1, near: 1, zoom: { min: 0.9, average: 1.2, max: 1.3 } },
      sceneObjectsPosition: { x: -6.5, y: 0, z: 10 },
    };
    makeObservable(this, {
      notifications: observable,
    });
  }

  init() {
    super.init();
    this.tutorial.open();
  }

  setModels() {
    // Поворот хука в сторону крана
    const hook = _.find(this.#models, { block_type: MODEL.HOOK });
    hook.direction = 315;
    // Добавление окружения сцены
    this.#models.push(
      { block_type: 10, x: 6, y: 0, z: 1, direction: null, exit_side: null },
      { block_type: 11, x: 6.5, y: 0, z: 0, direction: null, exit_side: null },
    );

    super.setModels(this.#models);
  }

  changeCommandInStack(type) {
    if (type === CONTROL_COMMANDS.HOOK) {
      this.stack[this.stack.length - 1] = new TheCommandHook(CONTROL_COMMANDS.HOOK).build();
    } else {
      this.stack[this.stack.length - 1] = new TheCommandUnhook(CONTROL_COMMANDS.UNHOOK).build();
    }
  }

  executeCommandStack() {
    const command = this.stack[this.currentCommandIndex];
    if (!command || !super.getCommandExecutionPermission) {
      return;
    }

    command.modifier = GAME_BUTTON_MODIFICATIONS.PERFORMED;
    command.run();

    this.endpointCheck(command);
    super.reloadCommand({ delay: 1000, command });
  }

  endpointCheck(command) {
    const position = {
      basic: _.find(this.models, { block_type: MODEL.BUILDING_WITH_HOOK }).position,
      checkpoint: _.find(this.models, { block_type: MODEL.CHECKPOINT }).position,
    };
    const isValidation = _.isEqual(position.checkpoint, position.basic);

    if (!this.isBuildingHooked && isValidation) {
      command.modifier = GAME_BUTTON_MODIFICATIONS.DONE;
      super.successfulExecution({ position: position.basic });
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    } else if (
      this.stack.length - 1 === this.currentCommandIndex &&
      !isValidation &&
      super.getCommandExecutionPermission
    ) {
      this.notifications.open({ type: NOTIFICATION_TYPES.NOT_COMPLETED, delay: 500 });
      command.modifier = GAME_BUTTON_MODIFICATIONS.FAIL;
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    }

    if (!super.getCommandExecutionPermission) {
      command.modifier = GAME_BUTTON_MODIFICATIONS.FAIL;
    }
  }

  get isDisabledBlock() {
    return super.isCommandStarted;
  }
}
