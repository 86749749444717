import React from 'react';

export const ForwardArrowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8518 10.626C17.6318 10.8614 17.3517 10.9847 17.0117 10.9958C16.6717 11.0061 16.3916 10.8935 16.1716 10.6581L10.291 4.36597V18.7159C10.291 19.0797 10.1758 19.3849 9.9454 19.6315C9.71577 19.8772 9.43094 20 9.09091 20C8.75088 20 8.46565 19.8772 8.23522 19.6315C8.0056 19.3849 7.89079 19.0797 7.89079 18.7159V4.36597L2.0102 10.6581C1.79018 10.8935 1.51015 11.0061 1.17012 10.9958C0.830083 10.9847 0.550055 10.8614 0.330033 10.626C0.110011 10.3906 0 10.091 0 9.72713C0 9.3633 0.110011 9.06367 0.330033 8.82825L8.25083 0.353132C8.35084 0.224721 8.47565 0.133548 8.62526 0.0796151C8.77568 0.0265386 8.93089 0 9.09091 0C9.25093 0 9.40094 0.0265386 9.54095 0.0796151C9.68097 0.133548 9.81098 0.224721 9.93099 0.353132L17.8518 8.82825C18.0718 9.06367 18.1818 9.3633 18.1818 9.72713C18.1818 10.091 18.0718 10.3906 17.8518 10.626Z"
        fill="#FFF9E0"
      />
      <path
        d="M17.8518 10.626C17.6318 10.8614 17.3517 10.9847 17.0117 10.9958C16.6717 11.0061 16.3916 10.8935 16.1716 10.6581L10.291 4.36597V18.7159C10.291 19.0797 10.1758 19.3849 9.9454 19.6315C9.71577 19.8772 9.43094 20 9.09091 20C8.75088 20 8.46565 19.8772 8.23522 19.6315C8.0056 19.3849 7.89079 19.0797 7.89079 18.7159V4.36597L2.0102 10.6581C1.79018 10.8935 1.51015 11.0061 1.17012 10.9958C0.830083 10.9847 0.550055 10.8614 0.330033 10.626C0.110011 10.3906 0 10.091 0 9.72713C0 9.3633 0.110011 9.06367 0.330033 8.82825L8.25083 0.353132C8.35084 0.224721 8.47565 0.133548 8.62526 0.0796151C8.77568 0.0265386 8.93089 0 9.09091 0C9.25093 0 9.40094 0.0265386 9.54095 0.0796151C9.68097 0.133548 9.81098 0.224721 9.93099 0.353132L17.8518 8.82825C18.0718 9.06367 18.1818 9.3633 18.1818 9.72713C18.1818 10.091 18.0718 10.3906 17.8518 10.626Z"
        fill="#3D3729"
      />
    </svg>
  );
};
