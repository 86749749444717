const Binder = {
  getAllMethods: (instance, cls) => {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(instance)).filter((name) => {
      const method = instance[name];
      return !(!(method instanceof Function) || method === cls);
    });
  },
  bind: (instance, cls) => {
    Binder.getAllMethods(instance, cls).forEach((mtd) => {
      try {
        const mtdFunc = instance[mtd].bind(instance);
        instance[mtd] = (...args) => {
          return mtdFunc(...args);
        };
      } catch (e) {}
    });
  },
};

export default Binder;
