import React, { Fragment } from 'react';
import LevelParametersAbstract, { MODEL } from '../LevelParametersAbstract';
import TheCommandForward from '../../ControlCommands/commands/TheCommandForward';
import TheCommandToLeft from '../../ControlCommands/commands/TheCommandToLeft';
import TheCommandToRight from '../../ControlCommands/commands/TheCommandToRight';
import { CONTROL_COMMANDS } from '../../ControlCommands/CommandAbstract';
import { makeObservable, observable } from 'mobx';
import { BUTTON_EVENT, NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import { endGame, fail_4, fail_5, level2_button_step4, level2_step8 } from '../../../ui';
import { BUTTON_MODIFICATIONS } from '../../../common/constants';

const tutorialPreset = {
  1: {
    title: 'Добро пожаловать на 2 уровень',
    content: (
      <Fragment>
        <span>На текущем уровне кошечка управляет грузовиком</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  2: {
    title: 'Изменения',
    content: (
      <Fragment>
        <span>
          Теперь управление немного изменилось, все действия персонажа будут происходить не сразу, а через запуск
          последовательности действий, состоящего из одной команды
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  3: {
    title: 'Блок алгоритма',
    media: { type: 'img', path: level2_step8 },
    content: (
      <Fragment>
        <span>
          Здесь будут отображаться доступные команды и ваша последовательность действий, для того, чтобы добавить в
          последовательность действий новую команду, вам нужно нажать на нужную команду из левого поля
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  4: {
    title: 'Запуск алгоритма',
    content: (
      <Fragment>
        <span>Запуск последовательности действий производится по кнопке</span>
        <img src={level2_button_step4} alt="Старт" />
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  5: {
    title: 'Проигрыш',
    content: (
      <Fragment>
        <span>При столкновении грузовика со стеной или препятствием сразу будет засчитано поражение</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  6: {
    title: 'Задание',
    content: (
      <Fragment>
        <span>Кошечке необходимо доехать на машине до конечной точки</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.START,
  },
};
const modelSettings = {
  [MODEL.STAGE_ENVIRONMENT]: {
    scale: 0.00277,
    path: '/models/level_2/sceneEnvironment/Level_02.fbx',
  },
  [MODEL.BASIC]: {
    scale: 0.0012,
    path: '/models/level_2/truck/Truck.fbx',
  },
  [MODEL.OBSTACLE]: {
    scale: 0.0018,
    path: '/models/level_2/obstacle/RoadSigns(Small)_Pit.fbx',
  },
  [MODEL.CHECKPOINT]: {
    scale: 0.01,
    path: '/models/common/checkpoint/SignExit.fbx',
  },
  [MODEL.SCENE_EDGE]: {
    scale: 1,
    path: '',
  },
};
const commandsPreset = {
  [CONTROL_COMMANDS.FORWARD]: new TheCommandForward(CONTROL_COMMANDS.FORWARD),
  [CONTROL_COMMANDS.TO_LEFT]: new TheCommandToLeft(CONTROL_COMMANDS.TO_LEFT),
  [CONTROL_COMMANDS.TO_RIGHT]: new TheCommandToRight(CONTROL_COMMANDS.TO_RIGHT),
};
const notificationsPreset = {
  [NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE]: {
    media: { type: 'video', path: fail_4 },
    content: <span>Машинка врезалась в знак, старайтесь ездить аккуратнее, тогда у вас точно получится!</span>,
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE]: {
    media: { type: 'video', path: fail_5 },
    content: (
      <span>
        Лучше не выезжать за пределы поля, пока Вы не доехали до цели, попробуйте составить алгоритм снова, у Вас точно
        получится!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
      { event: BUTTON_EVENT.INVITATION, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
    ],
  },
  [NOTIFICATION_TYPES.CHECKPOINT]: {
    content: <span>Вы отлично справились со своей работой! Можем переходить к следующему уровню!</span>,
    buttons: [{ event: BUTTON_EVENT.FURTHER, modifier: BUTTON_MODIFICATIONS.POSITIVE }],
  },
  [NOTIFICATION_TYPES.INVITATION]: {
    media: { type: 'video', path: endGame },
    content: (
      <span>
        Вы неплохо себя показали, попробуйте ещё раз, чтобы добиться лучшего результата! Будем ждать вас в Алабуге!
      </span>
    ),
    buttons: [
      { event: BUTTON_EVENT.EXIT, modifier: BUTTON_MODIFICATIONS.NEGATIVE },
      { event: BUTTON_EVENT.RESTART, modifier: BUTTON_MODIFICATIONS.POSITIVE },
    ],
  },
};

export default class SecondLevel extends LevelParametersAbstract {
  #models;
  constructor(models) {
    super({ modelSettings, commandsPreset, tutorialPreset, notificationsPreset });
    this.#models = models;
    this.roadCells = [];

    this.canvasPreset = {
      camera: { position: [5, 8, 5], fov: 60, aspect: 1, near: 1, zoom: { min: 1.3, average: 1.6, max: 1.9 } },
      sceneObjectsPosition: { x: -3, y: 0, z: -3 },
    };
    makeObservable(this, {
      roadCells: observable,
    });
  }

  init() {
    super.init();
    this.tutorial.open();
  }

  setModels() {
    // Добавление окружения сцены
    this.#models.push({ block_type: 10, x: 3, z: 3, y: 0, direction: null });
    super.setModels(this.#models);
  }

  addCommandInStack(command) {
    if (!this.stack.length) {
      super.addCommandInStack(command);
    }
  }

  executeCommandStack() {
    const command = super.executeCommandStack();
    if (!command || !super.getCommandExecutionPermission) {
      return;
    }
    command.run();
    super.endCommand();
    super.removeCommandFromStack(command);
    super.endpointCheck();
  }
}
