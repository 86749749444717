import React, { Suspense, useMemo, useRef } from 'react';
import { useFBX, useTexture } from '@react-three/drei';

export function Obstacle({ object }) {
  const obstacleRef = useRef();
  const obstacleFBX = useFBX(object.path);
  const position = object.position;
  const scale = object.scale;

  obstacleFBX.children[0].castShadow = true;
  obstacleFBX.children[0].receiveShadow = true;
  obstacleFBX.children[0].material.shininess = 0;
  const cloneObstacle = useMemo(() => obstacleFBX.clone(), [obstacleFBX]);

  return (
    <group>
      <Suspense fallback={null}>
        <primitive
          ref={obstacleRef}
          scale={scale}
          position={[position.x, position.y, position.z]}
          object={cloneObstacle}
        />
      </Suspense>
    </group>
  );
}
