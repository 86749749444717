import { makeObservable, observable } from 'mobx';
import { BUTTON_EVENT } from '../Notifications/Notifications';
import { LocalStorage } from '../../common/localStorage';

export default class Tutorial {
  #tutorialPreset;
  constructor(tutorialPreset) {
    this.#tutorialPreset = tutorialPreset;
    this.data = null;
    this.step = LocalStorage.TUTORIAL_LAUNCHED ? 1 : Object.values(this.#tutorialPreset).length;
    makeObservable(this, {
      data: observable,
      step: observable,
    });
  }

  build() {
    const { title, media, content, button } = this.#tutorialPreset[this.step];
    this.data = {
      title,
      media,
      content,
      button: {
        event: button,
        isVisible: button !== BUTTON_EVENT.ACCESSIBLY,
      },
      callback: (event) => {
        if (event === BUTTON_EVENT.ACCESSIBLY) {
          this.next();
        } else if (event === BUTTON_EVENT.START) {
          LocalStorage.TUTORIAL_LAUNCHED = '';
          this.close();
        }
      },
    };
    setTimeout(() => {
      if (this.data) {
        this.data.button.isVisible = true;
      }
    }, 3000);
  }

  open() {
    this.build();
  }

  next() {
    this.step++;
    this.build();
  }

  close() {
    this.data = null;
    this.step = 1;
  }
}
