import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { MODEL } from '../../LevelParameters/LevelParametersAbstract';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';

export default class TheCommandForward extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isIntersectionCheck({ obstacles, x, z }) {
    return obstacles.find((el) => {
      return el.position.x === x && el.position.z === z;
    });
  }

  run() {
    const { obstacles, basic } = super.run();
    const position = {
      x: basic.position.x,
      y: basic.rotation.y,
      z: basic.position.z,
    };
    const interpolate = {
      x: Math.round(Math.sin(position.y) + position.x),
      z: Math.round(Math.cos(position.y) + position.z),
    };
    const intersection = this.isIntersectionCheck({
      obstacles: obstacles,
      x: interpolate.x,
      z: interpolate.z,
    });

    basic.position.x = interpolate.x;
    basic.position.z = interpolate.z;

    if (intersection) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      const intersectionType =
        intersection.block_type === MODEL.OBSTACLE
          ? NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE
          : NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE;
      let notificationDelay = 500;

      if (RootStore.appStore.currentLevel === 1) {
        notificationDelay = 800;
        setTimeout(() => {
          basic.position.x = position.x;
          basic.position.z = position.z;
        }, 500);
      }

      RootStore.appStore._levelParameters.notifications.open({
        type: intersectionType,
        delay: notificationDelay,
        callback: () => RootStore.appStore._levelParameters.turnOnCommandExecution(),
      });
    }
  }
}
