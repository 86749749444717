import React from 'react';
import '../src/ui/styles/global.scss';
import store from './store';
import { MobxRouter } from 'mobx-router';
import {
  TutorialModal,
  NotificationModal,
  SettingsModal,
  RotatePhone,
  FullScreenNotSupportedModal,
  ErrorModal,
} from './ui';

function App() {
  return (
    <div id="appLayout" className="app-layout">
      <MobxRouter store={store} />
      <TutorialModal />
      <NotificationModal />
      <SettingsModal />
      <RotatePhone />
      <FullScreenNotSupportedModal />
      <ErrorModal />
    </div>
  );
}

export default App;
