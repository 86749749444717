import React from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { IconButton, Modal, Tumbler } from '../../../index';
import { BUTTON_MODIFICATIONS, TUMBLER_MODIFICATIONS } from '../../../../common/constants';
import { SETTING_NAME, SETTING_TYPE, SETTINGS_CONFIGURATION } from '../../../../common/settingConfiguration';
import './style.scss';

function SettingsModal() {
  const { appStore } = RootStore;
  if (!appStore.settingModalFlag) {
    return;
  }

  const extra = ({ type, toggle, icon }) => {
    if (type === SETTING_TYPE.ICON) {
      return <IconButton modifier={BUTTON_MODIFICATIONS.WITHOUT_BACKGROUND}>{icon}</IconButton>;
    }
    if (type === SETTING_TYPE.TUMBLER) {
      let modifierType = TUMBLER_MODIFICATIONS.DEACTIVATED;
      if (appStore[`checkToggle${toggle}`]) {
        modifierType = TUMBLER_MODIFICATIONS.ACTIVE;
      }
      return <Tumbler modifier={modifierType} />;
    }
  };

  return (
    <Modal
      isOpen={!!appStore.settingModalFlag}
      onClose={() => appStore.closeSettingModal()}
      exitIcon
      header="Настройки"
      customClass="setting"
    >
      <div className="modal_body-info">
        {Object.values(SETTINGS_CONFIGURATION)
          .filter((element) => element.isVisible())
          .map((setting) => (
            <div key={setting.name} className="setting_item" onClick={setting.callBack}>
              {extra({ type: setting.type, toggle: setting.toggle, icon: setting.icon })}
              <span className="setting_item-text">{SETTING_NAME[setting.name]}</span>
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default observer(SettingsModal);
