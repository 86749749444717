import React, { Fragment } from 'react';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import classNames from '../../common/classNames';
import { BUTTON_MODIFICATIONS, CONTROL_COMMANDS_TYPE, GAME_BUTTON_MODIFICATIONS } from '../../common/constants';
import { ClearIcon, GameButton, IconButton, TextButton } from '../../ui';
import './style.scss';

const Command = observer(({ option }) => {
  const { appStore } = RootStore;
  const levelParameters = appStore._levelParameters;

  let commands;
  let callback;

  if (option === CONTROL_COMMANDS_TYPE.BLOCK) {
    commands = levelParameters.getCommands;
    callback = (args) => levelParameters.addCommandInStack(args);
  } else {
    commands = levelParameters.getStack;
    callback = (args) => levelParameters.removeCommandFromStack(args);
  }

  return (
    <>
      {commands.map((command) => (
        <GameButton
          key={command.key}
          modifier={
            (option === CONTROL_COMMANDS_TYPE.BLOCK && levelParameters.isDisabledBlock) || !appStore.isCanvasLoaded
              ? GAME_BUTTON_MODIFICATIONS.DISABLED
              : command.modifier
          }
          onClick={() => callback(command)}
          icon={appStore.currentLevel !== 4 ? command.interface.icon : null}
          title={command.interface.title}
        />
      ))}
    </>
  );
});

function ControlPanel() {
  const { appStore } = RootStore;
  const levelParameters = appStore._levelParameters;
  const currentLevel = appStore.currentLevel;
  const isStack = currentLevel !== 1;

  return (
    <div
      className={classNames({
        'control-panel': true,
        'control-panel--column': isStack,
      })}
    >
      <div className="control-panel_header">{isStack ? 'Блок' : 'Команды'}</div>
      {isStack && <div className="control-panel_header--stack">Путь</div>}
      <div className="control-panel_content">
        <Command option={CONTROL_COMMANDS_TYPE.BLOCK} />
      </div>
      {isStack && (
        <div
          className={classNames({
            'control-panel_content--stack': true,
            'control-panel_content--stack-disabled': levelParameters.isDisabledRun,
          })}
        >
          <Command option={CONTROL_COMMANDS_TYPE.STACK} />
        </div>
      )}
      <div className="control-panel_footer">
        {isStack && (
          <Fragment>
            <TextButton
              modifier={levelParameters.isDisabledRun ? BUTTON_MODIFICATIONS.DISABLED : BUTTON_MODIFICATIONS.POSITIVE}
              onClick={() => levelParameters.runningCommand()}
            >
              Старт
            </TextButton>
            <IconButton
              modifier={levelParameters.isDisabledRun ? BUTTON_MODIFICATIONS.DISABLED : BUTTON_MODIFICATIONS.SECONDARY}
              onClick={() => levelParameters.clearCommand()}
            >
              <ClearIcon />
            </IconButton>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default observer(ControlPanel);
