import React from 'react';

export const HookIcon = () => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.11503 0.203381C1.74736 0.459424 0.332031 3.77641 0.332031 4.38228C0.332031 5.04459 1.8042 8.42394 2.17353 8.60933C2.34036 8.69311 2.7442 8.76158 3.07103 8.76158C3.5542 8.76158 3.66536 8.82004 3.66536 9.07421C3.66536 9.59645 4.2562 10.0121 4.9987 10.0121H5.66536V10.8396C5.66536 11.9399 6.43203 13.1801 7.52136 13.8418C8.98053 14.7281 9.26903 15.8861 8.26536 16.8274C7.09553 17.9246 5.55753 17.3514 5.0802 15.6403C4.94853 15.168 4.71186 14.6931 4.55453 14.5852C4.09153 14.2676 3.04886 14.3418 2.64453 14.7211C2.33236 15.0138 2.30003 15.1816 2.37236 16.1308C2.4707 17.4198 3.0362 18.4004 4.1342 19.1859C5.04003 19.8338 5.95836 20.0637 7.32836 19.9852C9.08053 19.8849 10.5099 19.0108 11.2482 17.5882C11.7279 16.664 11.7927 14.7811 11.3752 13.8998C10.946 12.9941 10.3209 12.321 9.4087 11.7825C8.86553 11.4617 8.6157 11.1909 8.4972 10.7937C8.26403 10.0126 8.26436 10.0121 8.9987 10.0121C9.7412 10.0121 10.332 9.59645 10.332 9.07421C10.332 8.82004 10.4432 8.76158 10.9264 8.76158C11.2532 8.76158 11.657 8.69311 11.8239 8.60933C12.1924 8.42441 13.6654 5.04521 13.6654 4.38478C13.6654 3.72436 12.1924 0.345158 11.8239 0.160239C11.348 -0.0786091 2.46336 -0.0390617 2.11503 0.203381ZM4.65153 4.50186C5.11753 3.1907 5.4987 2.06524 5.4987 2.00084C5.4987 1.93644 5.24236 1.88376 4.9292 1.88376H4.3597L3.51253 4.2677C3.04653 5.57887 2.66536 6.70433 2.66536 6.76873C2.66536 6.83313 2.9217 6.88581 3.23486 6.88581H3.80436L4.65153 4.50186ZM8.47353 1.88376H7.89753C7.5807 1.88376 7.28653 1.97176 7.2437 2.07915C7.2007 2.18669 6.79453 3.31606 6.34086 4.58909L5.51603 6.90379L6.7217 6.80765L8.47353 1.88376ZM10.4849 4.50186C10.9509 3.1907 11.332 2.06524 11.332 2.00084C11.332 1.93644 11.0757 1.88376 10.7625 1.88376H10.193L9.34586 4.2677C8.87986 5.57887 8.4987 6.70433 8.4987 6.76873C8.4987 6.83313 8.75503 6.88581 9.0682 6.88581H9.6377L10.4849 4.50186Z"
        fill="#3D3729"
      />
    </svg>
  );
};
