const currentEvn = location.href.includes('hr.alabuga.ru') ? 'prod' : 'dev';

const _PLATFORM_URLS = {
  prod: {
    CLIENT: 'https://hr.alabuga.ru/',
    AUTH: 'https://auth.hr.alabuga.ru/',
  },
  dev: {
    CLIENT: 'https://dev.hr.alabuga.space/',
    AUTH: 'https://auth.dev.hr.alabuga.space/',
  },
};

export const PLATFORM_URLS = _PLATFORM_URLS[currentEvn];
