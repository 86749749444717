import React, { Suspense, useRef } from 'react';
import { useFBX } from '@react-three/drei';

export function Environment({ object }) {
  const ref = useRef();
  const modelFBX = useFBX(object.path);
  const scale = object.scale;
  modelFBX.children[0].material.shininess = 0;

  return (
    <group ref={ref} scale={scale} position={[6.5, 5, 0]}>
      <Suspense fallback={null}>
        <primitive object={modelFBX} />
      </Suspense>
    </group>
  );
}
