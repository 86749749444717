import React from 'react';

export const SettingIcon = () => {
  return (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3797_62665)">
        <path
          d="M0.332031 2C0.332031 0.895431 1.59617 0 3.15556 0H21.5085C23.0679 0 24.332 0.895431 24.332 2C24.332 3.10457 23.0679 4 21.5085 4H3.15556C1.59617 4 0.332031 3.10457 0.332031 2Z"
          fill="#3D3729"
        />
        <path
          d="M0.332031 10C0.332031 8.89543 1.59617 8 3.15556 8H21.5085C23.0679 8 24.332 8.89543 24.332 10C24.332 11.1046 23.0679 12 21.5085 12H3.15556C1.59617 12 0.332031 11.1046 0.332031 10Z"
          fill="#3D3729"
        />
        <path
          d="M0.332031 18C0.332031 16.8954 1.59617 16 3.15556 16H21.5085C23.0679 16 24.332 16.8954 24.332 18C24.332 19.1046 23.0679 20 21.5085 20H3.15556C1.59617 20 0.332031 19.1046 0.332031 18Z"
          fill="#3D3729"
        />
      </g>
      <defs>
        <clipPath id="clip0_3797_62665">
          <rect width="24" height="20" fill="white" transform="translate(0.332031)" />
        </clipPath>
      </defs>
    </svg>
  );
};
