import { makeObservable, observable } from 'mobx';
import RootStore from '../index';
import { LocalStorage } from '../../common/localStorage';
import _ from 'lodash';
import { MODEL } from '../LevelParameters/LevelParametersAbstract';

// Типы уведомлений.
// FAILED:
// 1. Столкновение с препятствием;
// 2. Столкновение с краем сцены;
// 3. Упал со здания;
// 4. Ниже нельзя;
// 5. Выше нельзя;
// 6. Здесь нет здания;
// 7. Здесь нет крепления для здания;
// SUCCESS:
// 1. Точка выхода;
export const NOTIFICATION_TYPES = {
  // failed
  COLLISION_WITH_OBSTACLE: 'collision_with_obstacle',
  COLLISION_WITH_OBSTACLE_WITHOUT_CARGO: 'collision_with_obstacle_without_cargo',
  COLLISION_WITH_SCENE_EDGE: 'collision_with_scene_edge',
  COLLISION_WITH_SCENE_EDGE_WITHOUT_CARGO: 'collision_with_scene_edge_without_cargo',
  FELL_OFF_BUILDING: 'fell_off_building',
  CANT_GO_LOWER: 'cant_go_lower',
  YOU_CANT_GO_HIGHER: 'you_cant_go_higher',
  NO_BUILDING: 'no_building',
  BUILDING_NOT_HOOKED: 'building_not_hooked',
  BUILDING_HAS_NO_HOOK: 'building_has_no_hook',
  NOT_COMPLETED: 'not_completed',
  // success
  CHECKPOINT: 'checkpoint',
  // invitation
  INVITATION: 'invitation',
};

export const BUTTON_EVENT = {
  ACCESSIBLY: 'accessibly',
  START: 'start',
  CONTINUE: 'continue',
  RESTART: 'restart',
  FURTHER: 'further',
  INVITATION: 'invitation',
  EXIT: 'exit',
};

export const BUTTON_TEXT = {
  [BUTTON_EVENT.ACCESSIBLY]: 'понятно',
  [BUTTON_EVENT.START]: 'начать',
  [BUTTON_EVENT.CONTINUE]: 'Продолжить',
  [BUTTON_EVENT.RESTART]: 'Перезапустить',
  [BUTTON_EVENT.FURTHER]: 'Далее',
  [BUTTON_EVENT.INVITATION]: 'Выйти',
  [BUTTON_EVENT.EXIT]: 'Выйти',
};

export default class Notifications {
  #notificationsPreset;
  constructor(notificationsPreset) {
    this.#notificationsPreset = notificationsPreset;
    this.data = null;
    makeObservable(this, {
      data: observable,
    });
  }

  open({ type, delay = 0, callback = undefined }) {
    setTimeout(async () => {
      if (type !== NOTIFICATION_TYPES.CHECKPOINT && type !== NOTIFICATION_TYPES.INVITATION) {
        await RootStore.appStore.fail();
      }
      this.data = {
        ...this.#notificationsPreset[type],
        onClose: (event) => {
          if (event === BUTTON_EVENT.RESTART) {
            this.reload();
          } else if (event === BUTTON_EVENT.FURTHER) {
            this.next();
          } else if (event === BUTTON_EVENT.INVITATION) {
            this.invitation();
          } else if (event === BUTTON_EVENT.EXIT) {
            this.exit();
          } else {
            this.close();
          }
        },
      };
      if (!!callback) {
        callback();
      }
    }, delay);
  }

  next() {
    RootStore.appStore.nextLevel();
  }

  close() {
    this.data = null;
  }

  reload() {
    RootStore.appStore.levelRestart(LocalStorage.LEVEL);
  }

  invitation() {
    this.close();
    this.open({ type: NOTIFICATION_TYPES.INVITATION });
  }

  exit() {
    RootStore.appStore.closeLevel();
  }
}
