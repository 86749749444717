import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';

export default class TheCommandRightward extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isIntersectionCheck({ x }) {
    return x > 11;
  }

  isSurfaceCheck({ y }) {
    return y === 0;
  }

  run() {
    const { basic } = super.run();
    const interpolateX = basic.position.x + 3;
    const isSurface = this.isSurfaceCheck({ y: basic.position.y });
    const isIntersection = this.isIntersectionCheck({ x: interpolateX });
    const notificationType =
      isIntersection && isSurface ? NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE : NOTIFICATION_TYPES.FELL_OFF_BUILDING;

    if (isIntersection || !isSurface) {
      basic.position.x += 1.5;
      RootStore.appStore._levelParameters.disableCommandExecution();
      RootStore.appStore._levelParameters.notifications.open({
        type: notificationType,
        delay: 500,
      });
    } else {
      basic.position.x += 3;
    }
  }
}
