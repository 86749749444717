import React from 'react';

export const RightwardArrowIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.374 17.8518C9.13857 17.6318 9.0153 17.3517 9.00417 17.0117C8.9939 16.6717 9.10647 16.3916 9.34189 16.1716L15.634 10.291L1.28411 10.291C0.920276 10.291 0.615085 10.1758 0.368537 9.94539C0.122844 9.71577 -4.1224e-07 9.43094 -3.97376e-07 9.09091C-3.82513e-07 8.75087 0.122844 8.46565 0.368538 8.23522C0.615085 8.0056 0.920276 7.89079 1.28411 7.89079L15.634 7.89079L9.34189 2.0102C9.10647 1.79018 8.9939 1.51015 9.00417 1.17012C9.0153 0.830082 9.13858 0.550054 9.374 0.330032C9.60942 0.11001 9.90904 -4.4109e-07 10.2729 -4.25186e-07C10.6367 -4.09283e-07 10.9363 0.11001 11.1717 0.330032L19.6469 8.25083C19.7753 8.35084 19.8665 8.47565 19.9204 8.62526C19.9735 8.77568 20 8.93089 20 9.09091C20 9.25093 19.9735 9.40094 19.9204 9.54095C19.8665 9.68097 19.7753 9.81098 19.6469 9.93099L11.1717 17.8518C10.9363 18.0718 10.6367 18.1818 10.2729 18.1818C9.90904 18.1818 9.60942 18.0718 9.374 17.8518Z"
        fill="#FFF9E0"
      />
      <path
        d="M9.374 17.8518C9.13857 17.6318 9.0153 17.3517 9.00417 17.0117C8.9939 16.6717 9.10647 16.3916 9.34189 16.1716L15.634 10.291L1.28411 10.291C0.920276 10.291 0.615085 10.1758 0.368537 9.94539C0.122844 9.71577 -4.1224e-07 9.43094 -3.97376e-07 9.09091C-3.82513e-07 8.75087 0.122844 8.46565 0.368538 8.23522C0.615085 8.0056 0.920276 7.89079 1.28411 7.89079L15.634 7.89079L9.34189 2.0102C9.10647 1.79018 8.9939 1.51015 9.00417 1.17012C9.0153 0.830082 9.13858 0.550054 9.374 0.330032C9.60942 0.11001 9.90904 -4.4109e-07 10.2729 -4.25186e-07C10.6367 -4.09283e-07 10.9363 0.11001 11.1717 0.330032L19.6469 8.25083C19.7753 8.35084 19.8665 8.47565 19.9204 8.62526C19.9735 8.77568 20 8.93089 20 9.09091C20 9.25093 19.9735 9.40094 19.9204 9.54095C19.8665 9.68097 19.7753 9.81098 19.6469 9.93099L11.1717 17.8518C10.9363 18.0718 10.6367 18.1818 10.2729 18.1818C9.90904 18.1818 9.60942 18.0718 9.374 17.8518Z"
        fill="#3D3729"
      />
    </svg>
  );
};
