import React from 'react';
import RootStore from '../store';
import { TOGGLE_TYPE } from './constants';
import { RestartIcon } from '../ui/icons/RestartIcon';
import { HomeIcon } from '../ui/icons/HomeIcon';

const SETTING = {
  RESTART: 'restart',
  EXIT_SIMULATION: 'exit_simulation',
  GO_OUT: 'go_out',
  SOUNDS: 'sounds',
  MUSIC: 'music',
  FULL_SCREEN_MODE: 'full_screen_mode',
};
export const SETTING_TYPE = {
  ICON: 'icon',
  TUMBLER: 'tumbler',
};
export const SETTING_NAME = {
  [SETTING.RESTART]: 'перезапустить',
  [SETTING.EXIT_SIMULATION]: 'выход на платформу',
  [SETTING.GO_OUT]: 'выход в игровое меню',
  [SETTING.SOUNDS]: 'звуки',
  [SETTING.MUSIC]: 'музыка',
  [SETTING.FULL_SCREEN_MODE]: 'полноэкранный режим',
};
export const SETTINGS_CONFIGURATION = {
  0: {
    isVisible: () => {
      return !!RootStore.appStore._levelParameters;
    },
    type: SETTING_TYPE.ICON,
    name: SETTING.RESTART,
    icon: <RestartIcon />,
    callBack: async () => {
      await RootStore.appStore.fail();
      RootStore.appStore.levelRestart(RootStore.appStore.currentLevel);
    },
  },
  1: {
    isVisible: () => {
      return true;
    },
    type: SETTING_TYPE.TUMBLER,
    name: SETTING.FULL_SCREEN_MODE,
    toggle: TOGGLE_TYPE.FULLSCREEN,
    callBack: () => RootStore.appStore.toggleFullscreen(),
  },
  2: {
    isVisible: () => {
      return true;
    },
    type: SETTING_TYPE.TUMBLER,
    name: SETTING.MUSIC,
    toggle: TOGGLE_TYPE.MUSIC,
    callBack: () => {
      RootStore.appStore.toggleMusics();
    },
  },
  3: {
    isVisible: () => {
      return !!RootStore.appStore._levelParameters;
    },
    type: SETTING_TYPE.ICON,
    name: SETTING.GO_OUT,
    icon: <HomeIcon />,
    callBack: async () => {
      await RootStore.appStore.fail();
      RootStore.appStore.closeLevel();
    },
  },
  4: {
    isVisible: () => {
      return !RootStore.appStore._levelParameters;
    },
    type: SETTING_TYPE.ICON,
    name: SETTING.EXIT_SIMULATION,
    icon: <HomeIcon />,
    callBack: () => RootStore.appStore.exitFromGame(),
  },
};
