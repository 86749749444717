import React, { Fragment } from 'react';
import LevelParametersAbstract, { MODEL } from '../LevelParametersAbstract';
import TheCommandForward from '../../ControlCommands/commands/TheCommandForward';
import TheCommandToLeft from '../../ControlCommands/commands/TheCommandToLeft';
import TheCommandToRight from '../../ControlCommands/commands/TheCommandToRight';
import { CONTROL_COMMANDS } from '../../ControlCommands/CommandAbstract';
import { BUTTON_EVENT, NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import Tutorial from '../../Tutorial/Tutorial';
import {
  level1_step2,
  level1_step3,
  level1_step4,
  level1_button_step5,
  level1_step6,
  level1_step7,
  level1_button_step7,
  level1_step8,
  level1_button_step8,
  level1_step9,
  level1_button_step9,
  level1_step10,
  level1_step11,
  fail_1,
  fail_2,
} from '../../../ui';
import { BUTTON_MODIFICATIONS } from '../../../common/constants';

const tutorialPreset = {
  1: {
    title: 'Добро пожаловать',
    content: (
      <Fragment>
        <span>
          В данной симуляции Вам предстоит попробовать себя в разных ролях на строительном объекте. Пора изучить
          интерфейс и управление
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  2: {
    title: 'Игровое поле',
    media: { type: 'img', path: level1_step2 },
    content: (
      <Fragment>
        <span>Здесь будут происходить все действия в игре. Игровое поле имеет размер 5 на 5 клеток</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  3: {
    title: 'Блок управления',
    media: { type: 'img', path: level1_step3 },
    content: (
      <Fragment>
        <span>С помощью данной панели Вы задаете команды игровому персонажу</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  4: {
    title: 'Игровой персонаж',
    media: { type: 'img', path: level1_step4 },
    content: (
      <Fragment>
        <span>
          Вы управляете девочкой-кошечкой, движение будет происходить в зависимости от того, куда она направлена
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  5: {
    title: 'Дополнительное меню',
    content: (
      <Fragment>
        <span>С помощью</span>
        <img src={level1_button_step5} alt="Настройки" />
        <span>Вы можете перейти в меню и изменить дополнительные настройки</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  6: {
    title: 'Управление',
    media: { type: 'img', path: level1_step6 },
    content: (
      <Fragment>
        <span>
          На данном уровне все управление происходит через нажатие на кнопки. При нажатии на кнопку действие происходит
          сразу же. Разберем каждую команду
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  7: {
    title: 'Команда “Вперед"',
    media: { type: 'video', path: level1_step7 },
    content: (
      <Fragment>
        <span>При нажатии на</span> <img src={level1_button_step7} alt="Вперед" />
        <span>кошечка будет перемещаться на одну клетку в ту сторону, куда она смотрит</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  8: {
    title: 'Команда “Налево"',
    media: { type: 'video', path: level1_step8 },
    content: (
      <Fragment>
        <img src={level1_button_step8} alt="Налево" />
        <span>В данном случае кошечка будет поворачиваться в левую сторону на 45 градусов</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  9: {
    title: 'Команда “Направо"',
    media: { type: 'video', path: level1_step9 },
    content: (
      <Fragment>
        <img src={level1_button_step9} alt="Направо" />
        <span>В данном случае кошечка будет поворачиваться в правую сторону на 45 градусов</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  10: {
    title: 'Препятствия',
    media: { type: 'img', path: level1_step10 },
    content: (
      <Fragment>
        <span>
          Также, стоит обратить внимание на различные помехи, через которые невозможно пройти и их нужно будет обходить.
          Если кошечка столкнется со стеной или коробкой на первом уровне, то это не будет проигрышем
        </span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  11: {
    title: 'Команда “Выход',
    media: { type: 'img', path: level1_step11 },
    content: (
      <Fragment>
        <span>В каждом уровне есть конечная точка, куда кошечке нужно прийти. Это её цель</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.ACCESSIBLY,
  },
  12: {
    title: 'Задание',
    content: (
      <Fragment>
        <span>Кошечке необходимо донести кошачью игрушку до выхода со склада</span>
      </Fragment>
    ),
    button: BUTTON_EVENT.START,
  },
};
const modelSettings = {
  [MODEL.STAGE_ENVIRONMENT]: {
    scale: 0.0044,
    path: '/models/level_1/sceneEnvironment/Level_01.fbx',
  },
  [MODEL.BASIC]: {
    scale: 0.0055,
    path: '/models/level_1/cat/CatMesh_Pose.fbx',
  },
  [MODEL.OBSTACLE]: {
    scale: 0.005,
    path: '/models/level_1/obstacle/Box_Open.fbx',
  },
  [MODEL.CHECKPOINT]: {
    scale: 0.01,
    path: '/models/common/checkpoint/SignExit.fbx',
  },
  [MODEL.SCENE_EDGE]: {
    scale: 1,
    path: '',
  },
};
const commandsPreset = {
  [CONTROL_COMMANDS.FORWARD]: new TheCommandForward(CONTROL_COMMANDS.FORWARD),
  [CONTROL_COMMANDS.TO_LEFT]: new TheCommandToLeft(CONTROL_COMMANDS.TO_LEFT),
  [CONTROL_COMMANDS.TO_RIGHT]: new TheCommandToRight(CONTROL_COMMANDS.TO_RIGHT),
};
const notificationsPreset = {
  [NOTIFICATION_TYPES.COLLISION_WITH_OBSTACLE]: {
    media: { type: 'video', path: fail_1 },
    content: <span>Кошечка столкнулась с коробкой, постарайтесь продумывать свои ходы точнее!</span>,
    buttons: [{ event: BUTTON_EVENT.CONTINUE, modifier: BUTTON_MODIFICATIONS.PRIMARY }],
  },
  [NOTIFICATION_TYPES.COLLISION_WITH_SCENE_EDGE]: {
    media: { type: 'video', path: fail_2 },
    content: (
      <span>
        Лучше не уходить с рабочего места, пока Вы не выполнили своё задание, старайтесь не выходить за пределы игрового
        поля!
      </span>
    ),
    buttons: [{ event: BUTTON_EVENT.CONTINUE, modifier: BUTTON_MODIFICATIONS.PRIMARY }],
  },
  [NOTIFICATION_TYPES.CHECKPOINT]: {
    content: <span>Вы отлично справились со своей работой! Можем переходить к следующему уровню!</span>,
    buttons: [{ event: BUTTON_EVENT.FURTHER, modifier: BUTTON_MODIFICATIONS.POSITIVE }],
  },
};

export default class FirstLevel extends LevelParametersAbstract {
  #models;
  constructor(models) {
    super({
      modelSettings,
      commandsPreset,
      tutorialPreset,
      notificationsPreset,
    });
    this.#models = models;
    this.tutorial = new Tutorial(tutorialPreset);

    this.canvasPreset = {
      camera: { position: [5, 8, 5], fov: 60, aspect: 1, near: 1, zoom: { min: 1.3, average: 1.6, max: 1.9 } },
      sceneObjectsPosition: { x: -3.5, y: 0, z: -3 },
    };
  }

  init() {
    super.init();
    this.tutorial.open();
  }

  setModels() {
    // Добавление окружения сцены
    this.#models.push({ block_type: 10, x: 3, y: 0, z: 3, direction: null });
    super.setModels(this.#models);
  }

  addCommandInStack(command) {
    if (!this.stack.length) {
      super.addCommandInStack(command);
      super.runningCommand();
    }
  }

  executeCommandStack() {
    const command = super.executeCommandStack();
    if (!command || !super.getCommandExecutionPermission) {
      return;
    }
    command.run();
    super.endCommand();
    super.removeCommandFromStack(command);
    super.endpointCheck();
  }
}
