import CommandAbstract from '../CommandAbstract';

export default class TheCommandToRight extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  run() {
    const { basic } = super.run();
    basic.rotation.y -= Math.PI / 4;
  }
}
