import React from 'react';
import { StageEnvironment } from '../../models/StageEnvironment/StageEnvironment';
import { InteractionObject } from '../../models/InteractionObject/InteractionObject';
import { Checkpoint } from '../../models/Checkpoint/Checkpoint';
import { Obstacle } from '../../models/Obstacle/Obstacle';
import { MODEL } from '../../../store/LevelParameters/LevelParametersAbstract';
import { House } from '../../models/House/House';
import { HouseWithHook } from '../../models/HouseWithHook/HouseWithHook';
import { CraneCustom } from '../../models/CraneCustom/CraneCustom';
import { Environment } from '../../models/Environment/Environment';
import { Box } from '../../models/Box/Box';

function Model({ object, level }) {
  if (object.block_type === MODEL.ENVIRONMENT) {
    return <Environment object={object} />;
  } else if (object.block_type === MODEL.STAGE_ENVIRONMENT) {
    return <StageEnvironment object={object} level={level} />;
  } else if (object.block_type === MODEL.BASIC) {
    return <InteractionObject object={object} />;
  } else if (object.block_type === MODEL.OBSTACLE) {
    if (level === 1) {
      return <Box object={object} />;
    } else {
      return <Obstacle object={object} />;
    }
  } else if (object.block_type === MODEL.CHECKPOINT && level !== 3) {
    return <Checkpoint object={object} />;
  } else if (object.block_type === MODEL.BUILDING) {
    return <House object={object} />;
  } else if (object.block_type === MODEL.BUILDING_WITH_HOOK) {
    return <HouseWithHook object={object} />;
  } else if (object.block_type === MODEL.HOOK) {
    return <CraneCustom object={object} level={level} />;
  }
}

export default Model;
