import React from 'react';

export const LeftwardArrowIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.626 1.14821C10.8614 1.36824 10.9847 1.64826 10.9958 1.9883C11.0061 2.32833 10.8935 2.60836 10.6581 2.82838L4.36597 8.70897L18.7159 8.70897C19.0797 8.70897 19.3849 8.82418 19.6315 9.0546C19.8772 9.28423 20 9.56905 20 9.90909C20 10.2491 19.8772 10.5344 19.6315 10.7648C19.3849 10.9944 19.0797 11.1092 18.7159 11.1092L4.36597 11.1092L10.6581 16.9898C10.8935 17.2098 11.0061 17.4898 10.9958 17.8299C10.9847 18.1699 10.8614 18.4499 10.626 18.67C10.3906 18.89 10.091 19 9.72713 19C9.3633 19 9.06367 18.89 8.82825 18.67L0.353132 10.7492C0.224721 10.6492 0.133547 10.5244 0.0796147 10.3747C0.0265382 10.2243 -3.90382e-07 10.0691 -3.97376e-07 9.90909C-4.04371e-07 9.74907 0.0265382 9.59906 0.0796147 9.45905C0.133547 9.31903 0.224721 9.18902 0.353132 9.06901L8.82825 1.14821C9.06367 0.928192 9.3633 0.81818 9.72713 0.81818C10.091 0.81818 10.3906 0.928192 10.626 1.14821Z"
        fill="#FFF9E0"
      />
      <path
        d="M10.626 1.14821C10.8614 1.36824 10.9847 1.64826 10.9958 1.9883C11.0061 2.32833 10.8935 2.60836 10.6581 2.82838L4.36597 8.70897L18.7159 8.70897C19.0797 8.70897 19.3849 8.82418 19.6315 9.0546C19.8772 9.28423 20 9.56905 20 9.90909C20 10.2491 19.8772 10.5344 19.6315 10.7648C19.3849 10.9944 19.0797 11.1092 18.7159 11.1092L4.36597 11.1092L10.6581 16.9898C10.8935 17.2098 11.0061 17.4898 10.9958 17.8299C10.9847 18.1699 10.8614 18.4499 10.626 18.67C10.3906 18.89 10.091 19 9.72713 19C9.3633 19 9.06367 18.89 8.82825 18.67L0.353132 10.7492C0.224721 10.6492 0.133547 10.5244 0.0796147 10.3747C0.0265382 10.2243 -3.90382e-07 10.0691 -3.97376e-07 9.90909C-4.04371e-07 9.74907 0.0265382 9.59906 0.0796147 9.45905C0.133547 9.31903 0.224721 9.18902 0.353132 9.06901L8.82825 1.14821C9.06367 0.928192 9.3633 0.81818 9.72713 0.81818C10.091 0.81818 10.3906 0.928192 10.626 1.14821Z"
        fill="#3D3729"
      />
    </svg>
  );
};
