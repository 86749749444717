import React from 'react';

export const DescendArrowIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.76382 18.4116L8.94244 18.3377L15.8377 11.4424C15.873 11.4071 15.897 11.3621 15.9068 11.3131C15.9165 11.2641 15.9115 11.2133 15.8924 11.1671C15.8732 11.1209 15.8409 11.0815 15.7993 11.0537C15.7578 11.0259 15.709 11.0111 15.6591 11.0111L11.9047 11.0111C11.4661 11.0111 11.1105 10.6555 11.1105 10.2169L11.1105 7.8702L6.41716 7.8702L6.41716 10.2169C6.41716 10.6555 6.06158 11.0111 5.62296 11.0111L1.86875 11.0111C1.81882 11.0111 1.76986 11.0259 1.72835 11.0537C1.6868 11.0815 1.65441 11.1209 1.63528 11.1671C1.61615 11.2133 1.61115 11.2641 1.6209 11.3131C1.63064 11.3621 1.65467 11.4071 1.68997 11.4424L8.58521 18.3377L8.76382 18.4116Z"
        fill="#3D3729"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76382 20C9.25203 20 9.72025 19.8061 10.0655 19.4609L16.9609 12.5655C17.2183 12.308 17.3936 11.98 17.4646 11.6229C17.5357 11.2658 17.4992 10.8957 17.3599 10.5593C17.2206 10.2229 16.9846 9.9354 16.6819 9.73308C16.3792 9.53077 16.0232 9.42275 15.6591 9.42267L12.6989 9.42267L12.6989 7.076C12.6989 6.63738 12.3433 6.2818 11.9047 6.2818L5.62296 6.2818C5.18434 6.2818 4.82876 6.63738 4.82876 7.076L4.82876 9.42267L1.86841 9.42267C1.50432 9.42275 1.14843 9.53077 0.845723 9.73308C0.543017 9.9354 0.307095 10.2229 0.167775 10.5593C0.0284546 10.8957 -0.00800438 11.2658 0.0630043 11.6229C0.134013 11.98 0.309392 12.3081 0.566798 12.5656L7.46213 19.4609C7.8074 19.8061 8.27562 20 8.76382 20ZM8.94244 18.3377C8.89505 18.385 8.83081 18.4116 8.76382 18.4116C8.69684 18.4116 8.6326 18.385 8.58521 18.3377L1.68997 11.4424C1.65467 11.4071 1.63064 11.3621 1.6209 11.3131C1.61115 11.2641 1.61615 11.2133 1.63528 11.1671C1.65441 11.1209 1.6868 11.0815 1.72835 11.0537C1.76986 11.0259 1.81882 11.0111 1.86875 11.0111L5.62296 11.0111C6.06158 11.0111 6.41716 10.6555 6.41716 10.2169L6.41716 7.8702L11.1105 7.8702L11.1105 10.2169C11.1105 10.6555 11.4661 11.0111 11.9047 11.0111L15.6591 11.0111C15.709 11.0111 15.7578 11.0259 15.7993 11.0537C15.8409 11.0815 15.8732 11.1209 15.8924 11.1671C15.9115 11.2133 15.9165 11.2641 15.9068 11.3131C15.897 11.3621 15.873 11.4071 15.8377 11.4424L8.94244 18.3377Z"
        fill="#3D3729"
      />
      <path
        d="M11.9047 4.72926C12.3433 4.72926 12.6989 4.37369 12.6989 3.93506C12.6989 3.49644 12.3433 3.14086 11.9047 3.14086L5.62297 3.14086C5.18434 3.14086 4.82877 3.49644 4.82877 3.93506C4.82877 4.37369 5.18434 4.72926 5.62297 4.72926L11.9047 4.72926Z"
        fill="#3D3729"
      />
      <path
        d="M11.9047 1.5884C12.3433 1.5884 12.6989 1.23282 12.6989 0.794197C12.6989 0.355575 12.3433 -4.50811e-07 11.9047 -4.89157e-07L5.62297 -1.03832e-06C5.18434 -1.07667e-06 4.82877 0.355574 4.82877 0.794196C4.82877 1.23282 5.18434 1.58839 5.62297 1.5884L11.9047 1.5884Z"
        fill="#3D3729"
      />
    </svg>
  );
};
