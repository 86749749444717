import React from 'react';

export const RotateIcon = ({ style, className }) => {
  return (
    <svg
      width="84"
      height="70"
      viewBox="0 0 84 70"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M4.86316 9.85056H32.5389V27.5467H37.4021V3.66127C37.4021 1.56912 35.7221 0 33.6884 0H3.71368C1.59158 0 0 1.65629 0 3.66127V61.1083C0 63.2005 1.68 64.7696 3.71368 64.7696H12.6442V50.5604H4.86316V9.85056ZM14.3242 3.66127H22.9011C23.6084 3.66127 24.1389 4.18431 24.1389 4.88169C24.1389 5.57908 23.6084 6.10212 22.9011 6.10212H14.3242C13.6168 6.10212 13.0863 5.57908 13.0863 4.88169C13.0863 4.18431 13.7053 3.66127 14.3242 3.66127Z"
        fill="inherit"
      />
      <path
        d="M80.198 33.0386H22.0169C19.8948 33.0386 18.2148 34.6949 18.2148 36.787V66.2516C18.2148 68.3437 19.8948 70 22.0169 70H80.198C82.2317 70 84.0001 68.3437 84.0001 66.2516V36.787C84.0001 34.7821 82.3201 33.0386 80.198 33.0386ZM25.4654 54.6575C23.6969 54.6575 22.3706 53.2628 22.3706 51.6065C22.3706 49.863 23.7854 48.4682 25.4654 48.4682C27.2338 48.4682 28.5601 49.863 28.5601 51.6065C28.5601 53.2628 27.1454 54.6575 25.4654 54.6575ZM73.9201 65.1183H32.6275V37.8331H73.9201V65.1183ZM80.198 55.7036C80.198 56.401 79.6675 56.924 78.9601 56.924C78.2527 56.924 77.7222 56.401 77.7222 55.7036V47.2478C77.7222 46.5504 78.2527 46.0274 78.9601 46.0274C79.6675 46.0274 80.198 46.5504 80.198 47.2478V55.7036Z"
        fill="inherit"
      />
      <path
        d="M52.7873 20.8344C52.3452 20.3985 52.1684 19.8755 52.1684 19.3524C52.1684 18.8294 52.3452 18.2192 52.7873 17.8705C53.6715 16.9988 54.9979 16.9988 55.8821 17.8705L57.7389 19.7011C57.12 14.0349 52.2568 9.50187 46.3326 9.50187C45.0947 9.50187 44.1221 8.54297 44.1221 7.32255C44.1221 6.10212 45.0947 5.14322 46.3326 5.14322C54.7326 5.14322 61.6294 11.6812 62.16 19.7883L64.2821 17.6961C65.1663 16.8244 66.4926 16.8244 67.3768 17.6961C68.261 18.5679 68.261 19.8755 67.3768 20.7472L61.6294 26.4135C60.7452 27.2852 59.4189 27.2852 58.5347 26.4135L52.7873 20.8344Z"
        fill="inherit"
      />
    </svg>
  );
};
