import AppStore from './AppStore';
import { RouterStore } from 'mobx-router';
import { makeAutoObservable } from 'mobx';

class RootStore {
  constructor() {
    this.router = new RouterStore(this);
    this.router.currentRoute = null;
    this.appStore = new AppStore(this.router);
    makeAutoObservable(this);
  }
}

export default new RootStore();
