import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';

/**
 * @param title - Текст для компоненты;
 * @param modifier - модификатор для компоненты:
 *  primary - background: green
 *  secondary - background: blue
 *  disabled - background: gray, pointer-events: none
 * @param customClass - Если необходим дополнительный класс
 * @param icon - иконка
 * @param onClick - CallBack
 */

function GameButton({ modifier, customClass, onClick, icon, title }) {
  return (
    <div
      className={classNames({
        notranslate: true,
        game_button: true,
        [`game_button--${modifier}`]: modifier,
        [`${customClass}`]: !!customClass,
      })}
      onClick={onClick}
    >
      {icon && <div className="game_button-icon">{icon}</div>}
      <span className="game_button-title">{title}</span>
    </div>
  );
}

export default GameButton;
