import RootStore from '../index';
import {
  DescendArrowIcon,
  ForwardArrowIcon,
  HookIcon,
  LeftArrowIcon,
  LeftwardArrowIcon,
  RightArrowIcon,
  RightwardArrowIcon,
  RiseArrowIcon,
} from '../../ui';
import React from 'react';
import { GAME_BUTTON_MODIFICATIONS } from '../../common/constants';
import { MODEL } from '../LevelParameters/LevelParametersAbstract';
import _ from 'lodash';

export const CONTROL_COMMANDS = {
  FORWARD: 'forward',
  TO_LEFT: 'to_left',
  LEFTWARD: 'leftward',
  TO_RIGHT: 'to_right',
  RIGHTWARD: 'rightward',
  HIGHER: 'higher',
  BELOW: 'below',

  CRANE_LEFT: 'crane_left',
  CRANE_RIGHT: 'crane_right',
  LOWER: 'lower',
  RAISE: 'raise',
  HOOK: 'hook',
  UNHOOK: 'unhook',
};
export const CONTROL_COMMANDS_HOTKEYS = {
  [CONTROL_COMMANDS.FORWARD]: 'ArrowUp',
  [CONTROL_COMMANDS.TO_LEFT]: 'ArrowLeft',
  [CONTROL_COMMANDS.LEFTWARD]: 'ArrowLeft',
  [CONTROL_COMMANDS.TO_RIGHT]: 'ArrowRight',
  [CONTROL_COMMANDS.RIGHTWARD]: 'ArrowRight',
  [CONTROL_COMMANDS.HIGHER]: 'ArrowUp',
  [CONTROL_COMMANDS.BELOW]: 'ArrowDown',

  [CONTROL_COMMANDS.CRANE_LEFT]: 'ArrowLeft',
  [CONTROL_COMMANDS.CRANE_RIGHT]: 'ArrowRight',
  [CONTROL_COMMANDS.RAISE]: 'ArrowUp',
  [CONTROL_COMMANDS.LOWER]: 'ArrowDown',
  [CONTROL_COMMANDS.HOOK]: 'SpaceHook',
  [CONTROL_COMMANDS.UNHOOK]: 'SpaceUnhook',
  ENTER: 'Enter',
};
export const CONTROL_COMMANDS_NAME = {
  [CONTROL_COMMANDS.FORWARD]: 'вперед',
  [CONTROL_COMMANDS.TO_LEFT]: 'налево',
  [CONTROL_COMMANDS.LEFTWARD]: 'влево',
  [CONTROL_COMMANDS.TO_RIGHT]: 'направо',
  [CONTROL_COMMANDS.RIGHTWARD]: 'вправо',
  [CONTROL_COMMANDS.HIGHER]: 'подняться',
  [CONTROL_COMMANDS.BELOW]: 'спуститься',

  [CONTROL_COMMANDS.CRANE_LEFT]: 'влево',
  [CONTROL_COMMANDS.CRANE_RIGHT]: 'вправо',
  [CONTROL_COMMANDS.RAISE]: 'поднять',
  [CONTROL_COMMANDS.LOWER]: 'опустить',
  [CONTROL_COMMANDS.HOOK]: 'зацепить',
  [CONTROL_COMMANDS.UNHOOK]: 'отцепить',
};
export const CONTROL_COMMANDS_SYMBOL = {
  [CONTROL_COMMANDS.FORWARD]: <ForwardArrowIcon />,
  [CONTROL_COMMANDS.TO_LEFT]: <LeftArrowIcon />,
  [CONTROL_COMMANDS.TO_RIGHT]: <RightArrowIcon />,

  [CONTROL_COMMANDS.LEFTWARD]: <LeftwardArrowIcon />,
  [CONTROL_COMMANDS.RIGHTWARD]: <RightwardArrowIcon />,
  [CONTROL_COMMANDS.HIGHER]: <RiseArrowIcon />,
  [CONTROL_COMMANDS.BELOW]: <DescendArrowIcon />,

  [CONTROL_COMMANDS.HOOK]: <HookIcon />,
  [CONTROL_COMMANDS.UNHOOK]: '✗',
};

export default class CommandAbstract {
  #command;
  constructor(command) {
    this.#command = command;
  }

  build() {
    return {
      key: `${Math.random()}_${this.#command}`,
      interface: {
        title: CONTROL_COMMANDS_NAME[this.#command],
        icon: CONTROL_COMMANDS_SYMBOL[this.#command],
      },
      modifier: GAME_BUTTON_MODIFICATIONS.EXPECTATION,
      block_type: this.#command,
      hotkey: CONTROL_COMMANDS_HOTKEYS[this.#command],
      run: () => this.run(),
      clone: () => this.build(),
    };
  }

  run() {
    const obstacles = RootStore.appStore._levelParameters.models.filter(
      (model) => model.block_type === MODEL.OBSTACLE || model.block_type === MODEL.SCENE_EDGE,
    );
    const basic = _.find(RootStore.appStore._levelParameters.models, { block_type: MODEL.BASIC });
    const checkpoint = _.find(RootStore.appStore._levelParameters.models, { block_type: MODEL.CHECKPOINT });
    const buildings = RootStore.appStore._levelParameters.models.filter(
      (model) => model.block_type === MODEL.BUILDING || model.block_type === MODEL.BUILDING_WITH_HOOK,
    );
    const buildingWithHook = _.find(RootStore.appStore._levelParameters.models, {
      block_type: MODEL.BUILDING_WITH_HOOK,
    });
    const hook = _.find(RootStore.appStore._levelParameters.models, { block_type: MODEL.HOOK });
    return {
      obstacles,
      basic,
      checkpoint,
      buildings,
      buildingWithHook,
      hook,
    };
  }
}
