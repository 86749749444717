import React, { Fragment } from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { Modal, TextButton } from '../../../index';
import { MODAL_SIZE } from '../../../../common/constants';
import { BUTTON_TEXT } from '../../../../store/Notifications/Notifications';

function NotificationModal() {
  const { appStore } = RootStore;
  if (!appStore._levelParameters?.notifications?.data) {
    return;
  }

  const data = appStore._levelParameters.notifications.data;
  const { content, media, buttons } = data;

  return (
    <Modal
      isOpen={!!data}
      onClose={() => data.onClose(buttons[0].event)}
      exitIcon
      size={media && MODAL_SIZE.BIG}
      media={
        !!media && (
          <Fragment>
            {media.type === 'video' ? (
              <video className="modal_body-media" alt="media" autoPlay loop playsInline key={media.path}>
                <source src={media.path} />
              </video>
            ) : (
              <img className="modal_body-media" src={media.path} alt="media" />
            )}
          </Fragment>
        )
      }
      footer={buttons.map((button, index) => (
        <TextButton key={index} modifier={button.modifier} onClick={() => data.onClose(button.event)}>
          {BUTTON_TEXT[button.event]}
        </TextButton>
      ))}
    >
      <div className="modal_body-info">{content}</div>
    </Modal>
  );
}

export default observer(NotificationModal);
