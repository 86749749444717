import React, { Suspense, useRef } from 'react';
import { Box, Cylinder, useFBX } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const speed = {
  moving: 0.2,
  rotation: 0.5,
};

export function Hook({ object, level }) {
  const hookRef = useRef();
  const cableRef = useRef();
  const modelFBX = useFBX(object.path);
  const position = object.position;
  const rotation = object.rotation;
  const scale = object.scale;

  modelFBX.children[0].castShadow = true;
  modelFBX.children[0].receiveShadow = true;
  modelFBX.children[0].material.shininess = 0;

  useFrame(() => {
    const hook = hookRef.current;
    const cable = cableRef.current;
    hook.position.x = THREE.MathUtils.lerp(hook.position.x, position.x, speed.moving);
    hook.position.y = THREE.MathUtils.lerp(hook.position.y, position.y - 0.2, speed.moving);
    hook.position.z = THREE.MathUtils.lerp(hook.position.z, position.z, speed.moving);
    hook.rotation.y = THREE.MathUtils.lerp(hook.rotation.y, rotation.y, speed.moving);

    if (level === 3) {
      let scaleForCable = 2;
      let positionForCable = 1.7;
      if (position.y === 3 || (position.y > 2 && position.y < 3)) {
        scaleForCable = 3;
        positionForCable = 2;
      } else if (position.y === 2 || (position.y > 1 && position.y < 2)) {
        scaleForCable = 4;
        positionForCable = 2.7;
      } else if (position.y === 1) {
        scaleForCable = 5;
        positionForCable = 3;
      }

      cable.scale.y = THREE.MathUtils.lerp(cable.scale.y, scaleForCable, speed.moving);
      cable.position.y = THREE.MathUtils.lerp(cable.position.y, positionForCable, speed.moving);
    }
  });

  return (
    <Suspense fallback={null}>
      <group ref={hookRef} position={[6.5, 4, -2]}>
        <primitive object={modelFBX} scale={scale} castShadow receiveShadow />
        <Cylinder
          ref={cableRef}
          args={[0.01, 0.01, level === 3 ? 1 : 9, 32]}
          position={[0, level === 3 ? 0 : 5, 0]}
          castShadow
          receiveShadow
        >
          <meshStandardMaterial roughness={1} metalness={1} color={'#000000'} />
        </Cylinder>
      </group>
    </Suspense>
  );
}
