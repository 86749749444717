export const MODAL_SIZE = {
  SMALL: 'small',
  MIDDLE: 'middle',
  BIG: 'big',
  DEFAULT: 'default',
};

export const BUTTON_MODIFICATIONS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DISABLED: 'disabled',
  MOVEMENT: 'movement',
  ACTION: 'action',
  WITHOUT_BACKGROUND: 'without_background',
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
};

export const GAME_BUTTON_MODIFICATIONS = {
  EXPECTATION: 'expectation',
  PERFORMED: 'performed',
  DONE: 'done',
  FAIL: 'fail',
  DISABLED: 'disabled',
};

export const TUMBLER_MODIFICATIONS = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  DISABLED: 'disabled',
};

export const CONTROL_COMMANDS_TYPE = {
  BLOCK: 'block',
  STACK: 'stack',
};

export const TOGGLE_TYPE = {
  SOUND: 'sound',
  MUSIC: 'music',
  FULLSCREEN: 'fullscreen',
};
