import React, { Suspense, Fragment, useRef } from 'react';
import { Hook } from './Hook';
import { useControls } from '../../hooks/useControls';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useFBX } from '@react-three/drei';

const speed = {
  // ToDo тест производительности
  moving: 1,
  rotation: 1,
  // moving: 0.2,
  // rotation: 0.5,
};

export function CraneCustom({ object, level }) {
  const towerRef = useRef();
  const winchRef = useRef();
  const basisFBX = useFBX('/models/common/crane/Leg11.fbx');
  const towerFBX = useFBX('/models/common/crane/Tower11.fbx');
  const winchFBX = useFBX('/models/common/crane/Winch11.fbx');
  if (level === 4) {
    useControls();
  }

  useFrame(() => {
    const tower = towerRef.current;
    const winch = winchRef.current;
    if (!tower || !winch) {
      return;
    }
    let angleY = 0;
    let positionWinch = { x: 6.5, y: 5.7, z: -2 };
    if (object.position.x === 2) {
      angleY = -Math.PI / 3.7;
      positionWinch = { x: 2, y: 5.7, z: 1 };
    } else if (object.position.x === 5) {
      angleY = -Math.PI / 8.7;
      positionWinch = { x: 5, y: 5.7, z: 1 };
    } else if (object.position.x === 8) {
      angleY = Math.PI / 8.7;
      positionWinch = { x: 8, y: 5.7, z: 1 };
    } else if (object.position.x === 11) {
      angleY = Math.PI / 3.7;
      positionWinch = { x: 11, y: 5.7, z: 1 };
    }
    tower.rotation.y = THREE.MathUtils.lerp(tower.rotation.y, angleY, speed.moving);
    winch.rotation.y = THREE.MathUtils.lerp(winch.rotation.y, angleY, speed.moving);
    winch.position.x = THREE.MathUtils.lerp(winch.position.x, positionWinch.x, speed.moving);
    winch.position.z = THREE.MathUtils.lerp(winch.position.z, positionWinch.z, speed.moving);
  }, 0);

  towerFBX.children[0].castShadow = true;
  winchFBX.children[0].castShadow = true;
  basisFBX.children[0].castShadow = true;
  towerFBX.children[0].receiveShadow = true;
  winchFBX.children[0].receiveShadow = true;
  basisFBX.children[0].receiveShadow = true;
  towerFBX.children[0].material.shininess = 0;
  winchFBX.children[0].material.shininess = 0;
  basisFBX.children[0].material.shininess = 0;

  return (
    <Suspense fallback={null}>
      <group>
        {level === 3 && (
          <Fragment>
            {/* Основа */}
            <primitive object={basisFBX} scale={0.35} position={[6.5, 0, -3]} castShadow receiveShadow />
            {/* Башня */}
            <primitive
              ref={towerRef}
              object={towerFBX}
              scale={0.35}
              position={[6.5, 4.4, -3]}
              castShadow
              receiveShadow
            />
            {/* Крепление лебедки */}
            <primitive
              ref={winchRef}
              object={winchFBX}
              scale={0.35}
              position={[6.5, 5.7, -2]}
              castShadow
              receiveShadow
            />
          </Fragment>
        )}
        {/* Крюк */}
        <Hook object={object} level={level} />
      </group>
    </Suspense>
  );
}
