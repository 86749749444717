import React from 'react';

export const RestartIcon = ({ style }) => {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M20.924 5.16417C21.5015 4.87615 21.5015 4.15611 20.924 3.86809L13.3709 0.101368C12.7933 -0.186649 12.0714 0.173373 12.0714 0.749407V2.74198C10.6073 2.84396 9.16071 3.1871 7.79547 3.76728C5.02334 4.94534 2.76037 7.02691 1.3964 9.65341C0.0324295 12.2799 -0.347142 15.2869 0.323073 18.1563C0.993289 21.0258 2.67133 23.5781 5.06812 25.3735C7.46491 27.1689 10.4304 28.095 13.4537 27.9923C16.477 27.8896 19.3688 26.7645 21.631 24.8109C23.8932 22.8572 25.3841 20.1973 25.8469 17.2894C26.1567 15.3428 25.9929 13.3666 25.386 11.5145C25.3406 11.3759 25.2926 11.238 25.2423 11.1008C25.163 10.8849 25.0776 10.6709 24.9861 10.4591C24.597 9.55759 23.4665 9.28582 22.6032 9.78646C21.8889 10.2006 21.5852 11.0141 21.6931 11.8041C21.7155 11.9687 21.7559 12.1322 21.8141 12.2909L21.8144 12.2919C22.3353 13.7101 22.4896 15.2412 22.2498 16.7477C21.9166 18.8414 20.8431 20.7565 19.2143 22.1632C17.5855 23.5698 15.5034 24.3799 13.3267 24.4538C11.1499 24.5277 9.01473 23.8609 7.28904 22.5682C5.56335 21.2756 4.35517 19.4379 3.87261 17.3719C3.39006 15.3059 3.66335 13.1409 4.64541 11.2498C5.62747 9.35873 7.2568 7.86 9.25274 7.0118C10.1548 6.62845 11.1062 6.38883 12.0714 6.29523V8.28285C12.0714 8.85888 12.7933 9.2189 13.3709 8.93089L20.924 5.16417Z"
        fill="#605539"
      />
    </svg>
  );
};
