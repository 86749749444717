import { useEffect, useState } from 'react';
import RootStore from '../../store/index';
import _ from 'lodash';
import { CONTROL_COMMANDS, CONTROL_COMMANDS_HOTKEYS } from '../../store/ControlCommands/CommandAbstract';

export const useControls = () => {
  const { appStore } = RootStore;
  const { hotkeys, commands } = appStore._levelParameters.getHotkeysCommands;
  const [kitHotkeys, setKitHotkeys] = useState(hotkeys);
  const [action, setAction] = useState(null);

  useEffect(() => {
    const keyDownPressHandler = (event) => {
      if (
        !appStore._levelParameters.isCommandStarted &&
        appStore._levelParameters.getCommandExecutionPermission &&
        appStore.isCanvasLoaded
      ) {
        if (event.code === CONTROL_COMMANDS_HOTKEYS.ENTER) {
          appStore._levelParameters.runningCommand();
        }

        if (
          event.code === 'Space' &&
          Object.keys(commands).includes(
            CONTROL_COMMANDS_HOTKEYS[CONTROL_COMMANDS.HOOK],
            CONTROL_COMMANDS_HOTKEYS[CONTROL_COMMANDS.UNHOOK],
          )
        ) {
          const lastCommandType =
            appStore._levelParameters.stack[appStore._levelParameters.stack.length - 1]?.block_type || '';
          if (
            appStore.currentLevel === 4 &&
            lastCommandType &&
            [CONTROL_COMMANDS.HOOK, CONTROL_COMMANDS.UNHOOK].includes(lastCommandType)
          ) {
            appStore._levelParameters.changeCommandInStack(
              lastCommandType === CONTROL_COMMANDS.HOOK ? CONTROL_COMMANDS.UNHOOK : CONTROL_COMMANDS.HOOK,
            );
          } else {
            const event = CONTROL_COMMANDS_HOTKEYS[CONTROL_COMMANDS.HOOK];
            setKitHotkeys({ ...kitHotkeys, event: true });
            setAction(commands[event]);
          }
          return;
        }

        if (!_.has(hotkeys, event.code)) {
          return;
        }
        setKitHotkeys({ ...kitHotkeys, [event.code]: true });
        setAction(commands[event.code]);
      }
    };
    window.addEventListener('keydown', keyDownPressHandler);
    return () => {
      window.removeEventListener('keydown', keyDownPressHandler);
    };
  }, []);

  useEffect(() => {
    if (!action) {
      return;
    }
    appStore._levelParameters.addCommandInStack(action);
  }, [hotkeys]);
};
