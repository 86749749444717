import React, { Suspense, useRef, useMemo } from 'react';
import { useFBX } from '@react-three/drei';

export function House({ object }) {
  const ref = useRef();
  let path = object.path;
  if (object.position.y === 1) {
    path = '/models/common/YellowHouse/HouseYellowBlock_V1.fbx';
  } else if (object.position.y === 2) {
    path = '/models/common/RedHouse/HouseRedBlock_V2.fbx';
  }
  const modelFBX = useFBX(path);
  modelFBX.children[0].castShadow = true;
  modelFBX.children[0].receiveShadow = true;
  modelFBX.children[0].material.shininess = 0;

  const position = object.position;
  const scale = object.scale;

  const cloneModel = useMemo(() => modelFBX.clone(), [modelFBX]);
  return (
    <group ref={ref} scale={scale} position={[position.x, position.y + 0.01, position.z]} rotation={[0, 0, 0]}>
      <Suspense fallback={null}>
        <primitive object={cloneModel} castShadow receiveShadow />
      </Suspense>
    </group>
  );
}
