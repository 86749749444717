import React from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { Modal, TextButton } from '../../../index';
import { BUTTON_MODIFICATIONS } from '../../../../common/constants';

function ErrorModal() {
  const { appStore } = RootStore;
  if (!appStore.error) {
    return;
  }

  return (
    <Modal
      isOpen={!!appStore.error}
      onClose={() => appStore.closeErrorModal()}
      exitIcon
      footer={
        <TextButton modifier={BUTTON_MODIFICATIONS.PRIMARY} onClick={() => appStore.closeErrorModal()}>
          Понятно
        </TextButton>
      }
    >
      <div className="modal_body-info">
        <span>Упс! Что-то пошло не так</span>
      </div>
    </Modal>
  );
}

export default observer(ErrorModal);
