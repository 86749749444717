import React from 'react';
import { GameCard, Header, LauncherLayout } from '../ui';
import { BUTTON_MODIFICATIONS } from '../common/constants';
import RootStore from '../store';
import { observer } from 'mobx-react';

function Launcher() {
  const { appStore } = RootStore;
  let levelOptions = [];
  for (let level = 1; level <= 4; level++) {
    levelOptions.push({
      level: level,
      title: `уровень ${level}`,
      access: appStore.progress >= level - 1,
      contents: [
        {
          title: 'Обучение',
          modifier: appStore.progress >= level - 1 ? BUTTON_MODIFICATIONS.SECONDARY : BUTTON_MODIFICATIONS.DISABLED,
          callBack: () => appStore.levelStart({ level: level, isForce: true, isTutorial: true }),
        },
        {
          title: 'Старт',
          modifier: appStore.progress >= level - 1 ? BUTTON_MODIFICATIONS.PRIMARY : BUTTON_MODIFICATIONS.DISABLED,
          callBack: () => appStore.levelStart({ level: level, isForce: true }),
        },
      ],
    });
  }
  return (
    <>
      <Header />
      <LauncherLayout>
        {levelOptions.map((option, index) => (
          <GameCard key={option.level + index} title={option.title} access={option.access} contents={option.contents} />
        ))}
      </LauncherLayout>
    </>
  );
}

export default observer(Launcher);
