import React from 'react';
import './style.scss';
import logo from '../../../assets/media/background/logo.png';

function LauncherLayout({ children }) {
  return (
    <div className="launcher">
      <div className="launcher-layout">
        <img className="launcher-logo" src={logo} alt="background" />
        <div className="launcher-layout-children">{children}</div>
      </div>
    </div>
  );
}

export default LauncherLayout;
