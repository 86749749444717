import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';
import { MODEL } from '../../LevelParameters/LevelParametersAbstract';

export default class TheCommandUnhook extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isCheckUnhook({ buildings, buildingWithHook }) {
    const isCheckSurfaces = buildings.find((building) => {
      if (
        building.block_type === MODEL.BUILDING &&
        building.position.x === buildingWithHook.position.x &&
        building.position.y === Math.floor(buildingWithHook.position.y - 1)
      ) {
        return building;
      }
    });
    const isCheckSurfacesNotBuilding = Math.floor(buildingWithHook.position.y) === 0;
    if (!isCheckSurfaces && !isCheckSurfacesNotBuilding) {
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.FELL_OFF_BUILDING,
        delay: 500,
      });
    }
    return !!isCheckSurfaces || isCheckSurfacesNotBuilding;
  }

  run() {
    const { buildings, buildingWithHook, hook } = super.run();

    const interpolateY = () => {
      const findBuilding = buildings.find((building) => {
        if (building.block_type === MODEL.BUILDING && building.position.x === buildingWithHook.position.x) {
          return building;
        }
      });
      if (!findBuilding) {
        return -0.1;
      } else {
        return findBuilding.position.y + 0.8;
      }
    };

    if (!RootStore.appStore._levelParameters.isBuildingHooked) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.BUILDING_NOT_HOOKED,
        delay: 500,
      });
      return;
    }
    if (!this.isCheckUnhook({ buildings: buildings, buildingWithHook: buildingWithHook })) {
      buildingWithHook.position.y = interpolateY();
      RootStore.appStore._levelParameters.disableCommandExecution();
      return;
    }
    hook.position.y = +(hook.position.y - 0.1).toFixed(2);
    buildingWithHook.position.y = +(buildingWithHook.position.y - 0.1).toFixed(2);
    setTimeout(() => {
      hook.position.y = +(hook.position.y + 0.45).toFixed(2);
      hook.rotation.y = (5 * Math.PI) / 3;
    }, 600);
    RootStore.appStore._levelParameters.isBuildingHooked = false;
  }
}
