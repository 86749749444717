import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';

export default class TheCommandDescend extends CommandAbstract {
  constructor(command) {
    super(command);
  }

  isCheckBuilding({ z }) {
    const building = RootStore.appStore._levelParameters.models.filter(
      (model) => (model.block_type === 5 || model.block_type === 6) && model.position.z === z,
    );
    if (!building.length) {
      RootStore.appStore._levelParameters.notifications.open({ type: NOTIFICATION_TYPES.CANT_GO_LOWER, delay: 800 });
    }
    return !!building.length;
  }

  getBuildingFloors({ x }) {
    const building = RootStore.appStore._levelParameters.models
      .filter((model) => (model.block_type === 5 || model.block_type === 6) && model.position.x === x)
      .map((el) => {
        return { x: el.position.x, y: el.position.y };
      });
    return { maxFloor: _.maxBy(building, 'y').y, minFloor: _.minBy(building, 'y').y };
  }

  run() {
    const { basic } = super.run();

    if (!this.isCheckBuilding({ z: Math.floor(basic.position.z) })) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      basic.position.y -= 0.1;
      return;
    }

    const { maxFloor, minFloor } = this.getBuildingFloors({ x: basic.position.x });

    if (basic.position.y > maxFloor) {
      basic.position.z += 0.6;
    }

    basic.position.y -= 1;

    if (basic.position.y === minFloor) {
      basic.position.z += 0.4;
    }
  }
}
