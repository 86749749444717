import React, { Suspense, useRef } from 'react';
import { useFBX } from '@react-three/drei';

export function StageEnvironment({ object, level }) {
  const ref = useRef();
  const modelFBX = useFBX(object.path);
  const position = object.position;
  const rotation = object.rotation;
  const scale = object.scale;

  let axesModel = {
    path: '/models/common/sceneEnvironment/Net_3level.fbx',
    position: {
      x: position.x + 0.5,
      y: position.y,
      z: position.z + 0.1,
    },
    scale: {
      x: 0.0031,
      y: 0.003,
      z: 0.002,
    },
  };
  if (level === 4) {
    axesModel = {
      path: '/models/common/sceneEnvironment/Net_4level2var.fbx',
      scale: { x: 0.0024, y: 0.001, z: 0.0055 },
      position: { x: position.x + 0.5, y: position.y, z: position.z + 1 },
    };
  }

  const axesFBX = useFBX(axesModel.path);
  modelFBX.children[0].castShadow = true;
  modelFBX.children[0].receiveShadow = true;
  modelFBX.children[0].material.forEach((el) => {
    el.shininess = 0;
  });

  return (
    <group ref={ref}>
      <Suspense fallback={null}>
        <primitive
          object={modelFBX}
          scale={scale}
          position={[position.x, position.y, position.z]}
          rotation={[rotation.x, rotation.y, rotation.z]}
        />
        {(level === 3 || level === 4) && (
          <primitive
            object={axesFBX}
            scale={[axesModel.scale.x, axesModel.scale.y, axesModel.scale.z]}
            position={[axesModel.position.x, axesModel.position.y, axesModel.position.z]}
          />
        )}
      </Suspense>
    </group>
  );
}
