import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';
import { TUMBLER_MODIFICATIONS } from '../../../common/constants';

/**
 * @param title - Текст для компоненты;
 * @param modifier - модификатор для компоненты:
 *  primary - background: green
 *  secondary - background: blue
 *  disabled - background: gray, pointer-events: none
 * @param customClass - Если необходим дополнительный класс
 */

function Tumbler({ modifier = TUMBLER_MODIFICATIONS.DEACTIVATED, customClass, onClick }) {
  return (
    <div
      className={classNames({
        tumbler: true,
        [`tumbler--${modifier}`]: modifier,
        [`${customClass}`]: !!customClass,
      })}
      onClick={onClick}
    />
  );
}

export default Tumbler;
