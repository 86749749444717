import React from 'react';
import { createRoot } from 'react-dom/client';
import './ui/styles/global.scss';
import App from './App';
import store from './store';
import { startRouter } from 'mobx-router';
import routes from './routes';
import { PLATFORM_URLS } from './common/platformUrls';
import Keycloak from 'keycloak-js';
import { LOCAL_STORAGE_KEYS, LocalStorage } from './common/localStorage';

const launchApplication = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  startRouter(routes, store);
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);

  window.store = store;
};

let initOptions = {
  url: PLATFORM_URLS.AUTH,
  realm: 'Alabuga',
  clientId: 'alb-game',
  onLoad: 'login-required',
};

let keycloak = Keycloak(initOptions);

keycloak
  .init({
    onLoad: initOptions.onLoad,
    // token: LocalStorage.USER_TOKEN_TRAINING || LocalStorage.USER_TOKEN,
    // refreshToken: LocalStorage.REFRESH_TOKEN,
  })
  .then((auth) => {
    if (!auth) {
      launchApplication();
      setTimeout(() => {
        store.router.goTo(routes.accessDenied);
      });
    } else {
      console.info('Authenticated');
      console.log('keycloak', keycloak);

      LocalStorage.USER_TOKEN = keycloak.token;
      LocalStorage.REFRESH_TOKEN = keycloak.refreshToken;
      LocalStorage.remove(LOCAL_STORAGE_KEYS.NEED_KEYCLOAK);

      let alreadyStartApp = false;
      const checkRefresh = () => {
        keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              console.info('Token refreshed' + refreshed);
              if (LocalStorage.USER_TOKEN) {
                LocalStorage.USER_TOKEN = keycloak.token;
                LocalStorage.REFRESH_TOKEN = keycloak.refreshToken;
              }
            } else {
              console.warn(
                'Token not refreshed, valid for ' +
                  Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
                  ' seconds',
              );
            }
            if (!alreadyStartApp) {
              launchApplication();
              alreadyStartApp = true;
            }
          })
          .catch(() => {
            console.error('Failed to refresh token');
            if (!alreadyStartApp) {
              launchApplication();
              alreadyStartApp = true;
            }
          });
      };
      // checkRefresh();
      if (!alreadyStartApp) {
        launchApplication();
        alreadyStartApp = true;
      }
      //Token Refresh
      setInterval(() => {
        checkRefresh();
      }, 10000);
    }
  })
  .catch(() => {
    console.error('Authenticated Failed');
    launchApplication();
    setTimeout(() => {
      store.router.goTo(routes.accessDenied);
    });
  });
