import React from 'react';
import { GameLayout, Header } from '../ui';
import { Scene, ControlPanel } from '../modules';
import RootStore from '../store';
import { observer } from 'mobx-react';

function Game() {
  const { appStore } = RootStore;
  if (!appStore._levelParameters) {
    return null;
  }

  return (
    <>
      <Header />
      <GameLayout>
        <Scene />
        <ControlPanel />
      </GameLayout>
    </>
  );
}

export default observer(Game);
