import React from 'react';

export const ExitIcon = ({ style }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8848 20.8848C18.6658 21.6658 19.9322 21.6658 20.7132 20.8848C21.4943 20.1037 21.4943 18.8374 20.7132 18.0563L13.4557 10.7988L20.512 3.74248C21.2931 2.96143 21.2931 1.6951 20.512 0.914052C19.731 0.133004 18.4647 0.133004 17.6836 0.914052L10.6273 7.9704L3.74264 1.08579C2.96159 0.304738 1.69526 0.304738 0.914214 1.08579C0.133165 1.86683 0.133166 3.13317 0.914214 3.91421L7.79883 10.7988L0.713042 17.8846C-0.0680066 18.6657 -0.0680067 19.932 0.713042 20.713C1.49409 21.4941 2.76042 21.4941 3.54147 20.713L10.6273 13.6273L17.8848 20.8848Z"
        fill="#3D3729"
      />
    </svg>
  );
};
