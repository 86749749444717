import CommandAbstract from '../CommandAbstract';
import RootStore from '../../index';
import { NOTIFICATION_TYPES } from '../../Notifications/Notifications';

export default class TheCommandRaise extends CommandAbstract {
  constructor(command) {
    super(command);
  }
  run() {
    const { buildingWithHook, hook } = super.run();
    const interpolateHookY = +(hook.position.y + 1).toFixed(2);
    const interpolateBuildingY = +(buildingWithHook.position.y + 1).toFixed(2);
    hook.position.y = interpolateHookY;
    if (RootStore.appStore._levelParameters.isBuildingHooked) {
      buildingWithHook.position.y = interpolateBuildingY;
    }
    if (
      (RootStore.appStore._levelParameters.isBuildingHooked && interpolateHookY >= 4) ||
      (!RootStore.appStore._levelParameters.isBuildingHooked && interpolateHookY >= 5)
    ) {
      RootStore.appStore._levelParameters.disableCommandExecution();
      RootStore.appStore._levelParameters.notifications.open({
        type: NOTIFICATION_TYPES.YOU_CANT_GO_HIGHER,
        delay: 800,
      });
    }
  }
}
